import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HostingVideoChapter } from '../../hosting-interfaces';

@Component({
  selector: 'or-chapter-list',
  templateUrl: './chapter-list.component.html',
  styleUrls: ['./chapter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ChapterListComponent {
  @Input() chapters: HostingVideoChapter[] = [];
  @Input() forceDisplayHours = false;
  @Output() clickChapter = new EventEmitter<HostingVideoChapter>();

  onClickChapter(chapter: HostingVideoChapter): void {
    this.clickChapter.emit(chapter);
  }
}
