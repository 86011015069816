import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

export interface ScriptResolve {
  name: string;
  loaded: boolean;
}

export interface Script {
  name: string;
  src: string | null;
  content: string | null;
  async?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private readonly renderer: Renderer2;
  private readonly rendererFactory = inject(RendererFactory2);

  private scripts: Record<Script['name'], ScriptResolve> = {};

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  load(scripts: Script[] = []): Observable<ScriptResolve[]> {
    const scripts$: Observable<ScriptResolve>[] = [];

    scripts.forEach((script) => {
      scripts$.push(this.loadScript(script));
    });

    return forkJoin(scripts$);
  }

  private loadScript(script: Script): Observable<ScriptResolve> {
    return new Observable((observer) => {
      if (!this.scripts[script.name]?.loaded) {
        this.scripts[script.name] = { name: script.name, loaded: false };

        const head: HTMLHeadElement = this.renderer.selectRootElement('head', true);

        const scriptEl: HTMLScriptElement = this.renderer.createElement('script');
        scriptEl.type = 'text/javascript';

        if (script.content) {
          scriptEl.text = script.content;
          this.scripts[script.name].loaded = true;

          observer.next({ name: script.name, loaded: true });
          observer.complete();
        } else {
          scriptEl.src = script.src;
          scriptEl.async = script.async ?? false;

          scriptEl.onload = (): void => {
            this.scripts[script.name].loaded = true;
            observer.next({ name: script.name, loaded: true });
            observer.complete();
          };

          scriptEl.onerror = (): void => {
            observer.next({ name: script.name, loaded: false });
            observer.complete();
          };
        }

        this.renderer.appendChild(head, scriptEl);
      } else {
        this.scripts[script.name].loaded = true;
        observer.next({ name: script.name, loaded: true });
        observer.complete();
      }
    });
  }
}
