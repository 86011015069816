import { Component, OnDestroy, PLATFORM_ID, ViewChild, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, combineLatest, Subject } from 'rxjs';
import { HeaderTitleService } from '../header/header-title.service';
import { BrandkitService } from '../brandkits/brandkit.service';
import {
  CtaBrandKit,
  HostableLayout,
  HostableLayoutData,
  HostingVideoChapter,
  WidgetName,
  HostingVideoAppearance,
} from '@openreel/frontend/common/hosting/hosting-interfaces';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HostingListViewService } from '../hosting-list/hosting-list-view.service';
import { PlaylistVideo } from '@openreel/frontend/common/hosting/hostable-layout/playlist-widget-layout/playlist-widget-layout.component';
import { HostableLayoutUtils } from '@openreel/frontend/common/hosting/hostable-layout/hostable-grid-layout/hostable-layout.utils';
import { HostingPlayerComponent } from '@openreel/frontend/common/hosting/video-player/player/player.component';
import { isNumber } from 'lodash-es';
import { PublicCommentsManagerService } from '../public-comments/public-comments-manager.service';
import { HostableGridLayoutComponent } from '@openreel/frontend/common/hosting/hostable-layout/hostable-grid-layout/hostable-grid-layout.component';
import { PublicHostedVideo } from '../interfaces';
import { LayoutManagerService } from './layout-manager.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { EventName } from '../analytics/analytics.interfaces';
import { AuthService } from '../services/auth.service';

interface VideoData {
  srcUrl: string;
  thumbUrl: string;
  title: string;
  expired: boolean;
  slug: string;
  idHash: string;
  accountId: string;
  description: string;
  altText?: string;
  chapters?: HostingVideoChapter[];
  activeLayout?: HostableLayout;
  brandKit?: CtaBrandKit;
  appearance?: HostingVideoAppearance;
  token?: string;
  enableComments?: boolean; // Indicates if Public Comments feature is enabled
  commentsDisabled?: boolean; // Indicates if comments are disabled for the video
}

@Component({
  standalone: false,
  selector: 'openreel-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss'],
  providers: [PublicCommentsManagerService, LayoutManagerService],
})
export class VideoContainerComponent implements OnInit, OnDestroy {
  route = inject(ActivatedRoute);
  router = inject(Router);
  headerTitleService = inject(HeaderTitleService);
  brandkitService = inject(BrandkitService);
  hostingListViewService = inject(HostingListViewService);
  title = inject(Title);
  platformId = inject(PLATFORM_ID);
  document = inject(DOCUMENT);

  publicCommentsManagerService = inject(PublicCommentsManagerService);
  layoutManagerService = inject(LayoutManagerService);
  authService = inject(AuthService);
  analyticsService = inject(AnalyticsService);

  readonly WidgetName = WidgetName;

  readonly brandkitLoaded$: Observable<boolean> = this.brandkitService.brandKitLoaded$;
  readonly srcData$: Observable<VideoData> = this.prepareVideoData().pipe(tap((data) => (this._srcData = data)));
  readonly layoutData$: Observable<Partial<HostableLayoutData>> = this.getLayoutData();
  readonly videoToken$: Observable<string> = this.srcData$.pipe(map((srcData) => srcData?.token));
  readonly isAvailablePublicComments$ = this.srcData$.pipe(map((srcData) => srcData?.enableComments));
  readonly showCommentsWidget$ = this.layoutData$.pipe(
    map((layoutData) => layoutData.widgets.some((widget) => widget.name == WidgetName.Comments)),
  );

  readonly playlistVideos$: Observable<PublicHostedVideo[]> = this.hostingListViewService.videos$;
  readonly playlistVideosTotal$: Observable<number> = this.hostingListViewService.total$;
  readonly playlistVideosPageSize$: Observable<number> = this.hostingListViewService.pageSize$;
  readonly playlistVideosPageIndex$: Observable<number> = this.hostingListViewService.page$;
  readonly playlistVideosLoading$: Observable<boolean> = this.hostingListViewService.loading$;

  readonly isBrowser = isPlatformBrowser(this.platformId);

  readonly commentsCount$ = this.publicCommentsManagerService.total$;

  private _srcData?: VideoData;

  @ViewChild('hostingPlayer', { static: false })
  hostingPlayer?: HostingPlayerComponent;

  @ViewChild('hostableGridLayout', { static: false })
  hostableGridLayout?: HostableGridLayoutComponent;

  videoContainerSizeChangeSubs = this.layoutManagerService.videoContainerSizeChange$
    .pipe(debounceTime(500))
    .subscribe(() => {
      this.hostableGridLayout?.resizePlayerWidget();
    });

  commentsSubs = this.publicCommentsManagerService.comments$.subscribe(() => {
    this.layoutManagerService.resizeVideoContainer();
  });

  private readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.scrollToTop();
    this.subscribeToLogout();
  }

  ngOnDestroy(): void {
    this.headerTitleService.setVideoHeaderTitle('');
    this.videoContainerSizeChangeSubs?.unsubscribe();
    this.commentsSubs?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickVideo(video: PlaylistVideo): void {
    if (video.slug && video.idHash) {
      this.router.navigate([video.idHash + '-' + video.slug], { queryParamsHandling: 'merge' });
    }
  }

  clickChapter(chapter: HostingVideoChapter): void {
    if (isNumber(chapter.start) && !!this.hostingPlayer?.player) {
      this.hostingPlayer.currentTime(chapter.start);
    }
  }

  loadMorePlaylistVideos() {
    this.hostingListViewService.nextPage();
  }

  logStartCommentEvent() {
    this._logCommentEvent('video_comment_started', 'User has started typing the video comment');
  }
  logSubmitCommentEvent() {
    this._logCommentEvent('video_comment_submitted', 'User has submitted the video comment');
  }
  logUpdateCommentEvent() {
    this._logCommentEvent('video_comment_edited', 'User has clicked the Edit button for the video comment');
  }
  logDeleteCommentEvent() {
    this._logCommentEvent('video_comment_deleted', 'User has clicked the Delete button for the video comment');
  }

  private _logCommentEvent(eventName: EventName, description: string) {
    this.analyticsService.logEvent(eventName, description, {
      account_id: this.authService.user?.data?.account_id ?? null,
      position: this.hostingPlayer?.getCurrentTime() ?? 0,
      title: this._srcData?.title,
      url: this.router.url,
      id: this._srcData.idHash,
    });
  }

  private getLayoutData(): Observable<Partial<HostableLayoutData>> {
    return this.srcData$.pipe(
      map((data) => ({
        layoutId: data.activeLayout?.id,
        isCustom: data.activeLayout?.isCustom,
        widgets: HostableLayoutUtils.getLayoutWidgets(data.activeLayout),
        constraints: HostableLayoutUtils.getLayoutConstraints(data.activeLayout),
      })),
    );
  }

  private prepareVideoData(): Observable<VideoData> {
    const queryParams$ = this.route.queryParams;

    return combineLatest([this.route.data, queryParams$]).pipe(
      withLatestFrom(this.hostingListViewService.hubSlug$),
      tap(([[data, queryParams], hubSlug]) => {
        const { hub = '', slug = '', hubId = '' } = queryParams || {};
        const { title: videoTitle } = data.srcData;

        this.scrollToTop();
        this.headerTitleService.setVideoHeaderTitle(videoTitle);
        this.headerTitleService.setHubTitle(hub);
        this.headerTitleService.setHubSlug(slug);
        this.headerTitleService.setHubIdHash(hubId);

        if (!!slug && hubSlug !== slug) {
          this.hostingListViewService.setHub({
            slug: slug,
            title: hub,
            idHash: hubId,
            accountId: null,
            thumbnailSignedUrl: null,
            updatedAt: '',
            videoCount: null,
          });
        }
      }),
      map(([[data, _], __]) => ({ ...data.srcData, thumbUrl: data.srcData.thumbnailSignedUrl[0] })),
    );
  }

  private scrollToTop(): void {
    if (this.isBrowser) {
      this.document.documentElement.scrollTo({ left: 0, top: 0 });
    }
  }

  private subscribeToLogout(): void {
    this.authService.logoutSubject
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.publicCommentsManagerService.refreshComments();
      });
  }
}
