import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WidgetLayoutModule } from '../widget-layout/widget-layout.module';
import { PlaylistWidgetLayoutComponent } from './playlist-widget-layout.component';
import { OpenreelLogoModule } from '@openreel/ui/openreel-logo/openreel-logo.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrInfiniteScrollComponent } from '@openreel/ui/or-infinite-scroll/or-infinite-scroll.component';

@NgModule({
  declarations: [PlaylistWidgetLayoutComponent],
  imports: [
    CommonModule,
    WidgetLayoutModule,
    MatProgressSpinnerModule,
    MatIconModule,
    OpenreelLogoModule,
    MatTooltipModule,
    OrInfiniteScrollComponent,
  ],
  exports: [PlaylistWidgetLayoutComponent],
})
export class PlaylistWidgetLayoutModule {}
