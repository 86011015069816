import { LinkedInPostVisibility } from '../constants';

export interface LinkedInUploadInstruction {
  uploadUrl: string;
  firstByte: number;
  lastByte: number;
}

export interface RegisteredLinkedInMultiPartUpload {
  value: {
    uploadUrlsExpireAt: number;
    video: string;
    uploadInstructions: LinkedInUploadInstruction[];
    uploadToken: string;
  };
}

export interface CompleteLinkedInMultipartUploadRequest {
  finalizeUploadRequest: {
    video: string;
    uploadToken: string;
    uploadedPartIds: string[];
  };
}

export interface LinkedInPublishPostRequest {
  linkedInUserId: string;
  mediaTitle: string;
  postContent: string;
  postVisibility: LinkedInPostVisibility;
  videoUrn: string | null;
  accessToken: string;
}
