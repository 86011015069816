import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { WidgetName } from '../../hosting-interfaces';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { fromHtmlElementResizeEvent } from '../../../rxjs-utils';

export interface PlaylistVideo {
  id?: string;
  thumbnailSignedUrl?: string[];
  thumbnailUrl?: string;
  title: string;
  slug: string;
  idHash: string;
}

@Component({
  selector: 'or-playlist-widget-layout',
  templateUrl: './playlist-widget-layout.component.html',
  styleUrls: ['./playlist-widget-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PlaylistWidgetLayoutComponent implements OnInit, OnDestroy {
  WidgetName = WidgetName;

  cdr = inject(ChangeDetectorRef);
  @Input() isEnableConfigureMode: boolean = false;
  @Input() videos: PlaylistVideo[] = [];
  @Input() total: number = 0;
  @Input() pageSize: number = 24;
  @Input() pageIndex: number = 0;
  @Input() loading: boolean = false;

  @Output() clickVideo = new EventEmitter<PlaylistVideo>();
  @Output() loadMore = new EventEmitter<void>();

  containerSize: 'sm' | 'md' | 'lg' = 'lg';

  @ViewChild('contentEl', { static: true })
  private contenEl: ElementRef<HTMLDivElement>;

  private unsub$ = new Subject<void>();

  ngOnInit(): void {
    fromHtmlElementResizeEvent(this.contenEl.nativeElement)
      .pipe(takeUntil(this.unsub$), debounceTime(500))
      .subscribe((entries) => {
        const width = entries?.[0]?.borderBoxSize?.[0]?.inlineSize ?? entries?.[0]?.contentRect?.width ?? 0;
        this._setContainerSize(width);
      });
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  onClickVideo(event: Event, video: PlaylistVideo) {
    event.preventDefault();
    event.stopPropagation();
    this.clickVideo.emit(video);
  }

  onLoadMore() {
    this.loadMore.emit();
  }

  trackByVideoId(index: number, item: PlaylistVideo): string | number {
    return item.id;
  }

  private _setContainerSize(width: number) {
    const size = width < 250 ? 'sm' : width < 300 ? 'md' : 'lg';
    if (this.containerSize !== size) {
      this.containerSize = size;
      this.cdr.detectChanges();
    }
  }
}
