// Only videos with a maximum duration of 15 minutes can be uploaded to channels without the "Intermediate" feature.
export const YOUTUBE_ALLOWED_MAX_VIDEO_DURATION_FOR_UPLOAD = 15 * 60;

export enum YoutubeVideoPrivacyStatus {
  Public = 'public',
  Private = 'private',
  Unlisted = 'unlisted',
}

export enum YoutubeFeatureEligibility {
  Eligible = 'eligible',
  Allowed = 'allowed',
  Disallowed = 'disallowed',
}
