import { combineLatest, Observable } from 'rxjs';
import videojs from 'video.js';

import { NumberUtils } from '../../../../number.utils';
import { Comment } from '../../../hosting-interfaces';
import { isNullOrUndefined } from '../../../../../../../common/src/helpers/validation.helper';

const Plugin = videojs.getPlugin('plugin');

export interface SeekbarCommentsManager {
  readonly isEnable$: Observable<boolean>;
  readonly timecodedComments$: Observable<Comment[]>;
  readonly selectedComment$: Observable<Comment | null>;
}
export interface SeekbarCommentsOptions {
  manager: SeekbarCommentsManager;
  onClickComment?: (comment: Comment) => void;
}

export class SeekbarCommentsPlugin extends Plugin {
  private _player: videojs.Player;
  private _options: SeekbarCommentsOptions;

  private _seekbar?: videojs.Component;
  private _commentsContainer?: Element;

  private _duration: number = 0;

  private _unsubscribers: Array<() => void> = [];

  constructor(player: videojs.Player, options: SeekbarCommentsOptions) {
    super(player, options);
    this._player = player;
    this._options = options;
    if (options.manager) {
      this._getDurationAndControls(() => {
        this._makeComments();
      });
    }
  }

  private _getDurationAndControls(onReadyCallback: () => void) {
    const _loadedmetadataEventListener = () => {
      this._duration = this._player.duration();
      const controlBar = this._player.getChild('ControlBar');
      const progressControl = controlBar?.getChild('ProgressControl');
      this._seekbar = progressControl?.getChild('SeekBar');
      onReadyCallback();
    };

    this._player.on('loadedmetadata', _loadedmetadataEventListener);

    this._unsubscribers.push(() => this._player.off('loadedmetadata', _loadedmetadataEventListener));
  }

  private _makeComments() {
    if (!!this._options.manager) {
      const subs = combineLatest([
        this._options.manager.isEnable$,
        this._options.manager.timecodedComments$,
        this._options.manager.selectedComment$,
      ]).subscribe(([isEnable, comments, selectedComment]) => {
        this._remakeCommentsContainerEl();

        if (isEnable) {
          comments.forEach((comment) => {
            const commentEl = this._makeCommentEl(comment);

            if (!!this._options.onClickComment) {
              const commentClickListener = () => {
                this._options.onClickComment(comment);
              };
              commentEl.addEventListener('click', commentClickListener);
              this._unsubscribers.push(() => commentEl.removeEventListener('click', commentClickListener));
            }

            if (comment.id === selectedComment?.id) commentEl.className = commentEl.className + ' selected';
            this._commentsContainer.append(commentEl);
          });
        }

        this._seekbar!.el().append(this._commentsContainer);
      });

      const subs2 = this._options.manager.selectedComment$.subscribe((comment) => {
        if (comment && !isNullOrUndefined(comment.timecodeMs)) {
          this._player.currentTime(comment.timecodeMs);
        }
      });

      this._unsubscribers.push(() => {
        subs.unsubscribe();
        subs2.unsubscribe();
      });
    }
  }

  private _remakeCommentsContainerEl() {
    this._commentsContainer?.remove();
    this._commentsContainer = videojs.dom.createEl('div', {}, { class: 'vjs-seekbar-comments-container' }, null);
  }

  private _makeCommentEl(comment: Comment) {
    const start = comment.timecodeMs;
    const startPos = NumberUtils.toFixedNum((start / this._duration) * 100);
    const commentEl = videojs.dom.createEl(
      'div',
      {},
      { style: `--vjs-comment-start: ${startPos};`, class: 'vjs-seekbar-comment' },
      comment.createdBy?.fullname?.substring(0, 1),
    );
    return commentEl;
  }

  dispose(): void {
    super.dispose();
    this._unsubscribers.forEach((unsub) => unsub());
  }
}

videojs.registerPlugin('seekbarComments', SeekbarCommentsPlugin);

declare module 'video.js' {
  export interface VideoJsPlayer {
    seekbarComments: (options?: SeekbarCommentsOptions) => SeekbarCommentsPlugin;
  }

  export interface VideoJsPlayerPluginOptions {
    seekbarComments?: SeekbarCommentsOptions;
  }
}
