import { Component } from '@angular/core';
import { BrandkitService } from '../brandkits/brandkit.service';

@Component({
  standalone: false,
  selector: 'openreel-hosting-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss'],
})
export class AppContainerComponent {
  appStyle = {
    'font-family': '"Roboto", sans-serif',
  };
  constructor(private brandkitService: BrandkitService) {
    this.brandkitService.loadBrandkit();

    this.brandkitService.font$.subscribe((fontStyle) => (this.appStyle = { ...fontStyle }));
  }
}
