import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent, ErrorTypes } from './error-page/error-page.component';
import { HostingListComponent } from './hosting-list/hosting-list.component';
import { VideoContainerComponent } from './video-container/video-container.component';
import { AppContainerComponent } from './app-container/app-container.component';
import { EmbeddedPlayerComponent } from './embedded-player/embedded-player.component';
import { PasswordPageComponent } from './password-page/password-page.component';
import { VideoResolver } from './video.resolver';
import { HubResolver } from './hub.resolver';

const errorRoutes: Routes = Object.values(ErrorTypes).map((e) => ({
  path: e,
  component: ErrorPageComponent,
  data: {
    errorType: e,
  },
}));

const routes: Routes = [
  {
    path: 'embed/:identifier',
    component: EmbeddedPlayerComponent,
    resolve: {
      srcData: VideoResolver,
    },
    data: { embed: true },
  },
  {
    path: 'verify-password/:assetType/:identifier',
    component: PasswordPageComponent,
  },
  {
    path: '',
    component: AppContainerComponent,
    children: [
      ...errorRoutes,
      {
        path: ':identifier',
        component: VideoContainerComponent,
        resolve: {
          srcData: VideoResolver,
        },
      },
      {
        path: 'hub/:identifier',
        component: HostingListComponent,
        resolve: {
          hubData: HubResolver,
        },
      },
      {
        path: '**',
        redirectTo: 'not-found',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
