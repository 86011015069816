import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetLayoutComponent } from './widget-layout.component';

@NgModule({
  declarations: [WidgetLayoutComponent],
  imports: [CommonModule],
  exports: [WidgetLayoutComponent],
})
export class WidgetLayoutModule {}
