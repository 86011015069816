import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { KtdGridComponent, KtdGridLayout, ktdTrackById } from '@katoid/angular-grid-layout';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HostableLayoutUtils } from './hostable-layout.utils';
import { Widget, WidgetName } from '../../hosting-interfaces';
import { fromHtmlElementResizeEvent } from '../../../rxjs-utils/from-element-resize';

@Component({
  selector: 'or-hostable-grid-layout',
  templateUrl: './hostable-grid-layout.component.html',
  styleUrls: ['./hostable-grid-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HostableGridLayoutComponent implements OnInit, OnDestroy {
  @Input()
  get layout() {
    return this._layout;
  }
  set layout(data: KtdGridLayout) {
    if (!!data) {
      this._makeResponsiveGridLayout(this.cols, data);
      this.cdr.detectChanges();
      this.resizePlayerWidgetSub.next();
    }
    this._layout = data;
  }
  private _layout: KtdGridLayout;

  @Input()
  get widgets() {
    return this._widgets;
  }
  set widgets(widgets: Widget[]) {
    if (widgets !== this._widgets) {
      this.widgetsMap = (widgets ?? []).reduce((result, widget) => ({ ...result, [widget.name]: widget }), {});
    }
    this._widgets = widgets;
  }
  private _widgets: Widget[] = [];

  widgetsMap: Partial<Record<WidgetName, Widget>> = {};

  @Input() contentTemplate: TemplateRef<any>;

  @Input() enableCustomize: boolean;

  @Output() changeLayout = new EventEmitter<KtdGridLayout>();
  @Output() blockCustomization = new EventEmitter<boolean>();

  @ViewChild(KtdGridComponent, { static: false }) grid: KtdGridComponent;

  @ViewChild('layoutContainer', { static: true, read: ElementRef })
  layoutContainer: ElementRef<HTMLDivElement>;

  @ViewChild('playerWidgetContainer', { static: false })
  playerWidgetContainer: ElementRef<HTMLDivElement>;

  isBlockedCustomizationForSize: boolean = false;
  get customizable() {
    return this.enableCustomize && !this.isBlockedCustomizationForSize;
  }

  private _responsiveConstraints: KtdGridLayout = [];
  get layoutConstraints() {
    return this._responsiveConstraints;
  }

  private _isCustomizingGridItem: boolean = false;
  get isCustomizingGridItem() {
    return this._isCustomizingGridItem;
  }
  setIsCustomizingGridItem(value: boolean) {
    this._isCustomizingGridItem = value;
  }

  WidgetName = WidgetName;

  gridItemPadding: number = 12;
  cols: number = 12;
  rowHeight: number = 10;
  trackById = ktdTrackById;

  private resizePlayerWidgetSub = new Subject<void>();
  private unsub$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    fromHtmlElementResizeEvent(this.layoutContainer.nativeElement)
      .pipe(takeUntil(this.unsub$), debounceTime(500))
      .subscribe((entries) => {
        if (this.isCustomizingGridItem || !this.layout) return;
        const width = entries?.[0]?.borderBoxSize?.[0]?.inlineSize ?? entries?.[0]?.contentRect?.width ?? 0;
        this.gridItemPadding = width < 520 ? 4 : 12;
        const cols = width <= 900 ? 1 : 6;
        if (cols !== this.cols) {
          this._checkAndEmitBlockingStatus(cols);
          this._makeResponsiveGridLayout(cols, this.layout);
          this.cdr.detectChanges();
        }
        this.grid?.resize();
        this.resizePlayerWidgetSub.next();
      });

    this.resizePlayerWidgetSub.pipe(takeUntil(this.unsub$), debounceTime(500)).subscribe((_) => {
      this.resizePlayerWidget();
    });
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  changeConstraints(layout: KtdGridLayout) {
    this.layout = layout;
    this.changeLayout.emit(this._layout);
    this.cdr.detectChanges();
  }

  resizePlayerWidget() {
    if (!this.playerWidgetContainer?.nativeElement) return;
    const clientHeight = this.playerWidgetContainer?.nativeElement?.scrollHeight ?? 0;
    const gridItemHeight = Math.ceil((clientHeight + this.gridItemPadding * 2) / this.rowHeight);
    this._responsiveConstraints = this._responsiveConstraints.map((item) => ({
      ...item,
      h: item.id === WidgetName.VideoPlayer ? gridItemHeight : item.h,
    }));
    this.cdr.detectChanges();
    this.grid?.resize();
  }

  private _checkAndEmitBlockingStatus(cols: number) {
    this.isBlockedCustomizationForSize = cols !== 6;
    this.blockCustomization.emit(this.isBlockedCustomizationForSize);
  }

  private _makeResponsiveGridLayout(cols: number, layout: KtdGridLayout) {
    this._responsiveConstraints = HostableLayoutUtils.generateResponsiveGridLayout(cols, layout);
    this.cols = cols;
  }
}
