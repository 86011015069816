import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { WidgetCustomData, WidgetName } from '../../hosting-interfaces';
import { ContactUsService } from './contactus.service';
import { tapResponse } from '@ngrx/operators';

interface ContactUsWidgetState {
  isMessageSent: boolean;
  isLoading: boolean;
  error: Error | null;
}

@Component({
  selector: 'or-contactus-widget-layout',
  templateUrl: './contactus-widget-layout.component.html',
  styleUrls: ['./contactus-widget-layout.component.scss'],
  providers: [ContactUsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ContactUsWidgetLayoutComponent extends ComponentStore<ContactUsWidgetState> {
  @Input() isEnableConfigureMode: boolean = false;
  @Input() readonlyMode: boolean = false;
  @Input() widgetData: WidgetCustomData;
  @Input() layoutId: number;

  @Output() openSettings = new EventEmitter<WidgetCustomData>();

  contactusForm = this.fb.group({
    name: this.fb.control<string>('', [Validators.required, Validators.maxLength(100)]),
    email: this.fb.control<string>('', [Validators.required, Validators.email, Validators.maxLength(100)]),
    message: this.fb.control<string>('', [Validators.required, Validators.maxLength(1000)]),
  });

  isLoading$ = this.select((state) => state.isLoading);
  isMessageSent$ = this.select((state) => state.isMessageSent);

  WidgetName = WidgetName;

  constructor(
    private fb: FormBuilder,
    private contactUsService: ContactUsService,
    private toastr: ToastrService,
  ) {
    super({
      isLoading: false,
      error: null,
      isMessageSent: false,
    });
  }

  private readonly _sendEmail = this.effect(
    (dto$: Observable<Partial<{ layoutId: number; name: string; email: string; message: string }>>) =>
      dto$.pipe(
        tap((_) => this.patchState({ isLoading: true })),
        mergeMap((dto) =>
          this.contactUsService
            .sendEmail({
              layoutId: dto?.layoutId,
              senderEmail: dto.email,
              senderFullName: dto.name,
              message: dto.message,
            })
            .pipe(
              tapResponse(
                (result) => {
                  this.patchState({ isLoading: false, error: null, isMessageSent: true });
                },
                (error: Error) => {
                  this.patchState({ isLoading: false, error: error });
                  this.toastr.error('An error occured while sending message', 'Error!');
                },
              ),
            ),
        ),
      ),
  );

  submitForm() {
    if (this.readonlyMode) return;
    if (this.contactusForm.valid && !!this.layoutId) {
      this._sendEmail({ ...this.contactusForm.value, layoutId: this.layoutId });
    }
  }

  onClickSettings() {
    this.openSettings.emit(this.widgetData);
  }
}
