import { Directive, AfterViewInit, OnDestroy } from '@angular/core';
import { EMPTY, merge, Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil, tap, withLatestFrom, map } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

import { OverlayTimeframe } from '../player/overlay-plugin/overlay-timeframe.interface';
import { CtaEditorService } from './cta-editor.service';
import { HostingPlayerComponent } from '../player/player.component';
import { OverlayPosition } from '../../video-player/player/overlay-plugin/overlay-position.interface';

@Directive({
  selector: 'openreel-hosting-player[orCtaEditor]',
  standalone: false,
})
export class CtaEditorDirective implements AfterViewInit, OnDestroy {
  readonly duration$ = this.ctaEditorService.duration$;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly ctaEditorService: CtaEditorService,
    private readonly player: HostingPlayerComponent,
  ) {}

  ngAfterViewInit(): void {
    this.player.player.ready(() => {
      this.subscribeToToggleEditModeChanges();
      this.updateOverlayOnFormChanges();
      this.subscribeToDurationChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  private subscribeToToggleEditModeChanges(): void {
    this.ctaEditorService.toggleEditMode$
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap((ctaDefinition) => {
          const overlays = this.player.player.overlays();
          const cta = overlays.toggleEdit(ctaDefinition);

          if (cta) {
            const positionUpdated$ = cta.positionUpdated$.pipe(
              tap((position) => {
                this.ctaEditorService.setPosition(position);
              }),
            );

            const sizeUpdated$ = cta.sizeUpdated$.pipe(
              tap((size) => {
                this.ctaEditorService.setRelativeSize(size);
              }),
            );

            const timeFrameUpdated$ = cta.timeFrameUpdated$.pipe(
              withLatestFrom(this.player.duration$),
              tap(([timeFrame, duration]) => {
                this.ctaEditorService.setTimeFrame(this.adjustTimeFrame(timeFrame, duration));
              }),
            );

            return merge(positionUpdated$, timeFrameUpdated$, sizeUpdated$);
          }

          return EMPTY;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private updateOverlayOnFormChanges(): void {
    const buttonTextChanged$ = this.ctaEditorService.setCtaEditor$.pipe(
      map((c) => c.buttonText),
      distinctUntilChanged(isEqual),
      tap((buttonText) => {
        this.player.player.overlays().setOverlayText(buttonText);
      }),
    );

    const linkChanged$ = this.ctaEditorService.setCtaEditor$.pipe(
      map((c) => c.linkUrl),
      distinctUntilChanged(isEqual),
      tap((linkUrl) => {
        this.player.player.overlays().setOverlayLink(linkUrl);
      }),
    );

    const timeframeChanged$ = this.ctaEditorService.setCtaEditor$.pipe(
      map((c) => c.timeFrame),
      tap((timeFrame) => {
        const { start, end } = timeFrame;

        this.player.player.overlays().repositionOverlayMarkers(start, end);
      }),
    );

    const ctaOverlayPositionChanged$ = this.ctaEditorService.setCtaEditor$.pipe(
      map((c) => c.position),
      distinctUntilChanged(isEqual),
      filter((position) => typeof position === 'string'),
      tap((position) => {
        this.player.player.overlays().repositionOverlayButtonOnFormChanges(position as OverlayPosition);
      }),
    );

    merge(timeframeChanged$, ctaOverlayPositionChanged$, buttonTextChanged$, linkChanged$)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private subscribeToDurationChanges(): void {
    this.player.duration$.pipe(takeUntil(this.destroy$)).subscribe((duration) => {
      this.ctaEditorService.setDuration(duration);
    });
  }

  private adjustTimeFrame(timeFrame: OverlayTimeframe, duration: number): OverlayTimeframe {
    const { start, end } = timeFrame;

    return {
      start,
      end: end <= duration ? end : duration,
    };
  }
}
