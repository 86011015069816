<div class="chapter-list" *ngIf="chapters?.length; else emptyTemplate">
  <button *ngFor="let chapter of chapters" class="chapter-list-item" (click)="onClickChapter(chapter)">
    <div class="name-section">
      <span matTooltip="{{ chapter.name }}">{{ chapter.name }}</span>
    </div>
    <div class="duration-section">
      <mat-icon *ngIf="chapter.autogenerated" matTooltip="Auto-Generated">electric_bolt</mat-icon>
      <mat-icon>timer</mat-icon>
      <span>{{ chapter.start | parseDuration | formatDuration: { forceDisplayHours: forceDisplayHours } }}</span>
    </div>
  </button>
</div>

<ng-template #emptyTemplate>
  <div class="empty-template-container">
    <openreel-logo size="90px" color="#D8DAE5">
      <mat-icon>bookmarks</mat-icon>
    </openreel-logo>
    <p>No Chapters</p>
    <p>Add a chapter and it will be shown here</p>
  </div>
</ng-template>
