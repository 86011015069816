import { ApprovalWorkflowModes, ApprovalWorkflowParticipantEntities, ApprovalWorkflowRules } from '../constants';

export interface ApprovalWorkflow {
  id: number;
  name: string;
  accountId: number;
  mode: ApprovalWorkflowModes;
  rule: ApprovalWorkflowRules;
  locked: boolean;
  createdBy: number;
  createdAt: string;
  updatedAt: string;
  enabledAt: string;
  steps?: ApprovalWorkflowStep[];
  users?: ApprovalWorkflowUser[];
}

export interface ApprovalWorkflowStep {
  id: number;
  approvalWorkflowId: number;
  order: number;
  approvers: ApprovalWorkflowApprover[];
}

interface ApprovalWorkflowApproverUserEntity {
  id: number;
  entityType: ApprovalWorkflowParticipantEntities;
  entityId: number;
  entity: ApprovalWorkflowParticipant;
}

export interface ApprovalWorkflowParticipant {
  id: number;
  name: string;
  email?: string;
}

export interface ApprovalWorkflowApprover extends ApprovalWorkflowApproverUserEntity {
  approvalWorkflowStepId: number;
}

export interface ApprovalWorkflowUser extends ApprovalWorkflowApproverUserEntity {
  approvalWorkflowId: number;
}

export interface ApprovalWorkflowVideosCount {
  pendingVideosCount: number;
}

export interface CreateUpdateApprovalWorkflowDto {
  id: number | null;
  name: string;
  rule: ApprovalWorkflowRules;
  steps: CreateUpdateApprovalWorkflowStepDto[];
  users: CreateUpdateApprovalWorkflowUserDto[];
}

export interface CreateUpdateApprovalWorkflowStepDto {
  id: number | null;
  order: number;
  approvers: CreateUpdateApprovalWorkflowApproverDto[];
}

interface CreateUpdateApprovalWorkflowApproverUserDto {
  id: number | null;
  entityType: ApprovalWorkflowParticipantEntities;
  entityId: number;
  entityName: string | null;
  entityEmail: string | null;
}

export interface CreateUpdateApprovalWorkflowUserDto extends CreateUpdateApprovalWorkflowApproverUserDto {}
export interface CreateUpdateApprovalWorkflowApproverDto extends CreateUpdateApprovalWorkflowApproverUserDto {}
