import { Observable, OperatorFunction } from 'rxjs';
import { startWith } from 'rxjs/operators';

export function pairwiseWithDefault<T>(defaultValue: T, currentValue: T): OperatorFunction<T, [T, T]> {
  return (source$) =>
    new Observable((subscriber) => {
      let previousValue = defaultValue;

      return source$.pipe(startWith(currentValue)).subscribe((value) => {
        const oldPreviousValue = previousValue;

        previousValue = value;
        subscriber.next([oldPreviousValue, value]);
      });
    });
}
