import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ApiPagedResponse,
  Comment,
  CreatePublicCommentDTO,
  UpdatePublicCommentDTO,
} from '@openreel/frontend/common/hosting/hosting-interfaces';

@Injectable({
  providedIn: 'root',
})
export class PublicCommentsService {
  private baseUrl = environment.hostingPlaybackApiUrl;
  private http = inject(HttpClient);

  fetchPublicComments(videoToken: string, queryOptions: { page: number }): Observable<ApiPagedResponse<Comment>> {
    // Adding a timestamp to ensure each request is unique and prevent Angular from caching the results
    const timestamp = new Date().getTime();

    return this.http.get<ApiPagedResponse<Comment>>(`${this.baseUrl}hosting/public/comments`, {
      params: {
        videoToken,
        page: queryOptions.page,
        timestamp,
      },
    });
  }

  createPublicComment(
    videoToken: string,
    dto: CreatePublicCommentDTO,
  ): Observable<{ comment: Comment; token: string }> {
    return this.http.post<{ comment: Comment; token: string }>(`${this.baseUrl}hosting/public/comments`, dto, {
      params: {
        videoToken,
      },
    });
  }

  updatePublicComment(commentId: number, dto: UpdatePublicCommentDTO, commentToken?: string): Observable<Comment> {
    let params = new HttpParams();
    if (!!commentToken) params = params.append('commentToken', commentToken);
    return this.http.put<Comment>(`${this.baseUrl}hosting/public/comments/${commentId}`, dto, { params });
  }

  deletePublicComment(commentId: number, commentToken?: string): Observable<void> {
    let params = new HttpParams();
    if (!!commentToken) params = params.append('commentToken', commentToken);
    return this.http.delete<void>(`${this.baseUrl}hosting/public/comments/${commentId}`, { params });
  }
}
