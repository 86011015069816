<div class="password-container" openreelInitAnalytics="PUBLIC Analytics: ">
  <div class="heading">
    <img alt="logo" class="logo" src="assets/common/wordmarks/blue-gradient.svg" />
    <h2>Password Protected</h2>
    <p>Enter the password below</p>
  </div>
  <div class="divided-bar"></div>
  <div class="content">
    <p>
      This content is password protected - if you were not provided a password, please contact the person that provided
      you the link.
    </p>
    <div class="form-content">
      <mat-form-field appearance="outline">
        <input
          matInput
          (keyup.enter)="submitPassword()"
          [formControl]="password"
          [type]="hide ? 'password' : 'text'"
          placeholder="Enter Password"
          openreelTextTypedEventCapture
          [textTypedEventProperties]="eventProperties$ | async"
        />
        <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </mat-form-field>
      <button mat-flat-button color="primary" [disabled]="disabledBtn" (click)="submitPassword()">
        Submit Password
      </button>
    </div>
  </div>

  <div class="loader" *ngIf="loading$ | async"></div>
</div>
