import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import type {} from 'css-font-loading-module';
import { FontDto, LoadedFont, fontWeightStyleToVariant } from '@openreel/common';
import { FontService } from './font.service';
import { ProjectFont } from '@openreel/creator/common';

@Injectable({ providedIn: 'root' })
export class FontDOMService {
  // currently only used by canvas kit, because that needs to load fonts before rendering
  // it also needs the actual font file
  get loadedFonts() {
    return Array.from(this._loadedFonts.values());
  }
  private _loadedFonts = new Map<string, LoadedFont>();

  public readonly fontsLoading$ = new ReplaySubject<boolean>(1);

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly fontService: FontService,
  ) {
    this.fontsLoading$.next(false);
  }

  async addFontFaces(fonts: FontDto[]) {
    fonts.forEach((font) => this.fontService.addFontToCache(font));

    const fontFacesWithUrls = fonts
      .map((font) =>
        font.variants.map((variant) => ({
          id: font.id,
          source: font.source,
          fontUrl: variant.url,
          fontFace: new FontFace(font.family, `url(${variant.url})`, {
            weight: ['regular', 'italic'].includes(variant.slug) ? '400' : '700',
            style: ['regular', '700'].includes(variant.slug) ? 'normal' : 'italic',
          }),
        })),
      )
      .flat();

    for (const { fontFace, id, source } of fontFacesWithUrls) {
      const cacheKey = FontDOMService.getFontCacheKey({ id, source }, fontFace);
      if (this._loadedFonts.has(`${cacheKey}_${fontFace.weight}_${fontFace.style}`)) {
        continue;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.document.fonts as any).add(fontFace);
      this.createEmptySpan(fontFace.family, fontFace.weight, fontFace.style);
    }

    await this.document.fonts.ready;

    for (const { fontFace, fontUrl, id, source } of fontFacesWithUrls) {
      const cacheKey = FontDOMService.getFontCacheKey({ id, source }, fontFace);
      if (this._loadedFonts.has(cacheKey)) {
        continue;
      }

      const fontFile = await fetch(fontUrl).then((resp) => resp.arrayBuffer());

      this._loadedFonts.set(cacheKey, {
        id,
        source,
        fontFile,
        fontFamily: fontFace.family,
        fontVariant: fontWeightStyleToVariant(fontFace.weight, fontFace.style),
      });
    }
  }

  private static getFontCacheKey(font: ProjectFont, fontFace: FontFace) {
    return `${FontService.fontCacheKey(font)}_${fontFace.weight}_${fontFace.style}`;
  }

  // NOTE: for some unknown reason, Lottie wont render text correctly even though font is loaded through API
  // But if an element is present somewhere on the screen that is using that font, Lottie will render it correctly.
  // Workaround for this is to create a hidden span containing no text but using font family from loaded font
  private createEmptySpan(fontFamily: string, fontWeight: string, fontStyle: string) {
    const span = document.createElement('span');
    span.setAttribute(
      'style',
      `font-family: ${fontFamily}; font-weight: ${fontWeight}; font-style: ${fontStyle}; visibility: hidden`,
    );
    this.document.body.append(span);
  }
}
