import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { of, take } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { BrandkitService } from '../brandkits/brandkit.service';
import { HeaderTitleService } from '../header/header-title.service';
import { PublicHostedVideo } from '../interfaces';

@Component({
  standalone: false,
  selector: 'openreel-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
})
export class VideoCardComponent {
  brandkitLoaded$ = this.brandkitService.brandKitLoaded$;

  @Input()
  cardData: PublicHostedVideo;

  constructor(
    private router: Router,
    private brandkitService: BrandkitService,
    private headerTitleService: HeaderTitleService,
  ) {}

  toDetail(videoIdHash: string, videoSlug: string): void {
    const hubTitle$ = this.headerTitleService.headerHubTitle$;
    const hubSlug$ = this.headerTitleService.headerHubSlug$;
    const hubHashId$ = this.headerTitleService.headerHubIdHash$;

    of(videoSlug)
      .pipe(
        withLatestFrom(hubTitle$, hubSlug$, hubHashId$),
        tap(([videoSlug, hubTitle, hubSlug, hubHashId]) => {
          this.router.navigate(['', videoIdHash + '-' + videoSlug], {
            queryParams: {
              hub: hubTitle,
              slug: hubSlug,
              hubId: hubHashId,
            },
            queryParamsHandling: 'merge',
          });
        }),
        take(1),
      )
      .subscribe();
  }
}
