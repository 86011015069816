import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MetaService } from '../meta.service';
import { HubService } from '../services/hub.service';
import { VideoService } from '../services/video.service';
import { ToastrService } from 'ngx-toastr';
import { PasswordTokenService } from '../services/password-token.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventProperties } from '../analytics/analytics.interfaces';
import { map } from 'rxjs/operators';
import {
  getSlugFromIdentifier,
  getIdHashFromIdentifier,
} from '@openreel/frontend/common/hosting/hosting-identifier.utils';

@Component({
  standalone: false,
  selector: 'openreel-password-page',
  templateUrl: './password-page.component.html',
  styleUrls: ['./password-page.component.scss'],
  host: { ngSkipHydration: 'true' },
})
export class PasswordPageComponent implements OnInit {
  readonly loading$: Observable<boolean>;

  hide = true;
  password = new FormControl('', Validators.required);
  disabledBtn = false;

  eventProperties$: Observable<EventProperties>;

  private readonly loadingSubject = new BehaviorSubject(false);

  constructor(
    private toastrService: ToastrService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private videoService: VideoService,
    private hubService: HubService,
    private passwordTokenService: PasswordTokenService,
    private metaService: MetaService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.addVerifyPasswordMeta();

    this.loading$ = this.loadingSubject.asObservable();
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.setLoader(true);
    }

    this.eventProperties$ = this.route.params.pipe(
      map<Params, EventProperties>((params) => {
        const assetType = params['assetType'];
        const idHash = getIdHashFromIdentifier(params['identifier']);

        switch (assetType) {
          case 'video':
            return { id: idHash };
          case 'hub':
            return { hub_id: idHash };
          default:
            return {};
        }
      }),
    );
  }

  submitPassword() {
    if (!this.password.valid) {
      this.toastrService.error('Password is required', 'Error!');
      return;
    }
    const assetType = this.route.snapshot.params['assetType'];
    const { slug, idHash } = this.getIdentifiers();
    this.disabledBtn = true;
    if (assetType === 'video') {
      this.verifyVideoPassword(slug, idHash, this.password.value);
    } else if (assetType === 'hub') {
      this.verifyHubPassword(slug, idHash, this.password.value);
    }
  }
  verifyVideoPassword(slug: string, idHash: string, password: string) {
    this.videoService.verifyPassword(idHash, password).subscribe(
      (data) => {
        this.passwordTokenService.storeVideoToken(idHash, data.token);
        this.router.navigate(['/' + idHash + '-' + slug], {
          queryParams: this.route.snapshot.queryParams,
          queryParamsHandling: 'merge',
        });
      },
      () => {
        this.invalidPassword();
      },
    );
  }
  verifyHubPassword(slug: string, idHash: string, password: string) {
    this.hubService.verifyPassword(idHash, password).subscribe(
      (data) => {
        this.passwordTokenService.storeHubToken(data.token);
        this.router.navigate(['hub/' + idHash + '-' + slug]);
      },
      () => {
        this.invalidPassword();
      },
    );
  }

  invalidPassword() {
    this.toastrService.error('Invalid Password', 'Error!');
    this.disabledBtn = false;
  }

  private setLoader(loading: boolean): void {
    this.loadingSubject.next(loading);
  }

  private getIdentifiers(): { slug: string; idHash: string } {
    const slug = getSlugFromIdentifier(this.route.snapshot.params['identifier']);
    const idHash = getIdHashFromIdentifier(this.route.snapshot.params['identifier']);

    return { slug, idHash };
  }

  private addVerifyPasswordMeta(): void {
    const assetType = this.route.snapshot.params['assetType'];

    const { slug, idHash } = this.getIdentifiers();
    this.metaService.addVerifyPasswordPageMeta(idHash, slug, assetType);
  }
}
