import { BehaviorSubject, Subject, pipe, Observable, of, isObservable } from 'rxjs';
import { mergeScan, pluck, distinctUntilChanged } from 'rxjs/operators';

export type StateModifier<D, T> = (state: T, value: D) => T | Observable<T>;

export interface Modifier<D, T> {
  payload: D;
  modifier: StateModifier<D, T>;
}

export function pluckDistinct<T>(...keys: string[]) {
  return pipe(pluck<T>(...keys), distinctUntilChanged());
}

export class StoreSubject<T> extends BehaviorSubject<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private actionSource = new Subject<Modifier<any, T>>();

  constructor(init: T) {
    super(init);
    this.actionSource
      .pipe(
        mergeScan(
          (acc, val) => {
            const nxt = val.modifier(acc, val.payload);
            return isObservable(nxt) ? nxt : of(nxt);
          },
          init,
          1,
        ),
      )
      .subscribe(
        (v) => super.next(v),
        (e) => this.error(e),
        () => this.complete(),
      );
  }

  modify<D>(payload: D, modifier: StateModifier<D, T>) {
    this.actionSource.next({ payload, modifier });
  }

  select<K>(...keys: string[]): Observable<K> {
    return this.pipe(pluckDistinct<T>(...keys)) as Observable<K>;
  }

  next(value: T) {
    const modifier = (acc: T, value: T) => value;
    this.modify(value, modifier);
  }

  reset(state: T) {
    this.next(state);
  }
}
