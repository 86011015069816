import { Component, Input } from '@angular/core';

@Component({
  selector: 'openreel-logo',
  templateUrl: './openreel-logo.component.html',
  styleUrls: ['./openreel-logo.component.scss'],
  standalone: false,
})
export class OpenreelLogoComponent {
  @Input() size: string = '110px';
  @Input() color: string = '#0066ff';
}
