import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AnalyticsEventData } from './analytics.interfaces';
import { AnalyticsService } from './analytics.service';
import { AnalyticsHttpContextTokens } from './constants';

@Injectable()
export class AnalyticsInterceptor implements HttpInterceptor {
  constructor(private analyticsService: AnalyticsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isEnabledEventCapture = req.context.get(AnalyticsHttpContextTokens.ENABLE_EVENT_CAPTURE);
    if (isEnabledEventCapture) {
      return next.handle(req).pipe(
        tap({
          next: (result) => {
            if (result instanceof HttpResponse) {
              const successEventData = req.context.get(AnalyticsHttpContextTokens.SUCCESS_EVENT_DATA);
              this._logAnalyticsEventData(successEventData);
            }
          },
          error: (error) => {
            if (error instanceof HttpErrorResponse) {
              const failEventData = req.context.get(AnalyticsHttpContextTokens.FAIL_EVENT_DATA);
              this._logAnalyticsEventData(failEventData);
            }
          },
        }),
      );
    }

    return next.handle(req);
  }

  private _logAnalyticsEventData(eventData: AnalyticsEventData) {
    const { name, description, properties } = eventData;
    this.analyticsService.logEvent(name, description, properties);
  }
}
