import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseErrorHandlingResolver } from './base-error-handling.resolver';
import { VideoService } from './services/video.service';
import { PublicHostedVideo } from './interfaces';
import { MetaService } from './meta.service';
import {
  getSlugFromIdentifier,
  getIdHashFromIdentifier,
} from '@openreel/frontend/common/hosting/hosting-identifier.utils';

@Injectable({ providedIn: 'root' })
export class VideoResolver extends BaseErrorHandlingResolver<PublicHostedVideo> {
  constructor(
    private videoService: VideoService,
    router: Router,
    private metaService: MetaService,
    private title: Title,
  ) {
    super(router);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<PublicHostedVideo> {
    const identifier = route.params['identifier'];
    const slug = getSlugFromIdentifier(identifier);
    const idHash = getIdHashFromIdentifier(identifier);
    const isEmbed = !!route.data['embed'];
    const queryParams = route.queryParams;

    return this.videoService.getVideoData(idHash, slug, isEmbed).pipe(
      tap((d) => {
        this.metaService.addMetaForVideo(d);
        this.title.setTitle(d.title);
      }),
      catchError((e: HttpErrorResponse) => this.handleError(e, `video/${idHash}-${slug}`, queryParams)),
    );
  }
}
