export interface CaptionsCue {
  index: string | null;
  start: string;
  end: string;
  text: string;
}

export class CaptionsParser {
  parse(input: string): { cues: CaptionsCue[]; header: string } {
    let header = '';

    input = input.trim();
    input = input.replace(/\r\n/g, '\n');
    input = input.replace(/\r/g, '\n');

    const parts = input.split('\n\n');

    if (parts[0].startsWith('WEBVTT')) {
      header = parts.shift();
    }

    const cues = parts.map((part) => this.parseCue(part)).filter(Boolean);

    return {
      cues,
      header,
    };
  }

  fromCues(cues: CaptionsCue[], header: string): string {
    let captions = '';

    if (header) {
      captions += `${header}\n\n`;
    }

    for (const cue of cues) {
      if (cue.index) {
        captions += `${cue.index}\n`;
      }

      captions += `${cue.start} --> ${cue.end}\n`;
      captions += `${cue.text}\n\n`;
    }

    return captions.trim();
  }

  private parseCue(part: string): CaptionsCue | null {
    try {
      const lines = part.split('\n').filter(Boolean);

      let index: string | null = null;

      if (lines.length && lines[0].trim().startsWith('NOTE')) {
        return null;
      }

      if (lines.length && lines[0].trim().startsWith('STYLE')) {
        return null;
      }

      if (lines.length && (lines[1] || '').includes('-->')) {
        index = lines.shift()?.trim() ?? null;
      }

      let [start, end] = lines[0].split(' --> ');
      start = start.trim();
      end = end.trim();

      lines.shift();

      const text = lines.join('\n').trim();

      return {
        index,
        start,
        end,
        text,
      };
    } catch (error) {
      console.error('Error parsing cue', part, error);
      return null;
    }
  }
}
