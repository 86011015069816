import { FontDto } from './font.interfaces';

export interface BrandKitApplyBrandfetchDto {
  domain: string;
  color: string;
  logo: BrandfetchLogoFormat;
  watermark: BrandfetchLogoFormat;
  font: BrandfetchFont;
}

export interface BrandfetchLink {
  name: string;
  url: string;
}

export interface BrandfetchLogoFormat {
  src: string;
  format: 'svg' | 'png' | 'jpeg';
  background: 'transparent' | null;
  height: number;
  width: number;
  size: number;
}

export interface BrandfetchLogo {
  type: 'logo' | 'icon' | 'symbol' | 'other';
  theme: 'dark' | 'light' | null;
  tags: string[];
  formats: BrandfetchLogoFormat[];
}

export interface BrandfetchColor {
  hex: string;
  type: string;
  brightness: number;
}

export interface BrandfetchFont {
  name: string;
  type: 'title' | 'body';
  origin: 'google' | 'custom' | 'system' | null;
  originId: string | null;
}

export interface BrandfetchImageFormat {
  src: string;
  format: 'svg' | 'png' | 'jpeg';
  background: 'transparent' | null;
  height: number;
  width: number;
  size: number;
}

export interface BrandfetchImage {
  type: 'banner' | 'other';
  tags: string[];
  formats: BrandfetchImageFormat[];
}

export interface BrandfetchCompany {
  employees: number;
  foundedYear: number;
  industries: Industry[];
  kind: string;
  location: Location;
  name: string;
}

interface Industry {
  score: number;
  id: string;
  name: string;
  emoji: string;
  parent: null;
  slug: string;
}

interface Location {
  city: string;
  country: string;
  countryCode: string;
  region: string;
  state: string;
  subregion: string;
}

export interface BrandfetchSearch {
  name: string;
  domain: string;
  icon: string;
}

export interface BrandfetchSearchResponse {
  claimed: boolean;
  name: string;
  domain: string;
  icon: string;
  brandId: string;
}

export interface BrandfetchRetrieveBrand {
  domain: string;
  name: string;
  logos: BrandfetchLogo[];
  colors: BrandfetchColor[];
  fonts: FontDto[];
  images: BrandfetchImage[];
}

export interface BrandfetchRetrieveBrandResponse {
  id: string;
  claimed: boolean;
  description: string | null;
  longDescription: string | null;
  domain: string;
  name: string;
  links: BrandfetchLink[];
  logos: BrandfetchLogo[];
  colors: BrandfetchColor[];
  fonts: BrandfetchFont[];
  images: BrandfetchImage[];
  company: BrandfetchCompany;
  qualityScore: number;
  isNsfw: boolean;
}
