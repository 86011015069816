import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostingPlayerComponent } from './player/player.component';
import { ImageExtractorDirective } from './image-extractor/image-extractor.directive';
import { CtaEditorDirective } from './cta-editor/cta-editor.directive';

@NgModule({
  declarations: [HostingPlayerComponent, ImageExtractorDirective, CtaEditorDirective],
  imports: [CommonModule],
  exports: [HostingPlayerComponent, ImageExtractorDirective, CtaEditorDirective],
})
export class HostingVideoPlayerModule {}
