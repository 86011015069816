<div class="app-container">
  <div class="public-playlist">
    <ng-container [openreelHubEventCapture]="hubData$ | async">
      <!-- G R I D -->
      <ng-container *ngIf="hostingData$ | async as hostingData">
        <or-infinite-scroll
          *ngIf="hostingData.length"
          (page)="loadNextPage()"
          [length]="(total$ | async) || 0"
          [pageSize]="(pageSize$ | async) || 0"
          [nextContentLoaded]="!(showSpinner$ | async)"
        >
          <div class="hosting-grid-box">
            <div *ngFor="let data of hostingData" class="card-box">
              <openreel-video-card [cardData]="data"> </openreel-video-card>
            </div>
          </div>
        </or-infinite-scroll>

        <!-- E M P T Y   P A G E -->
        <div class="error-container" *ngIf="!hostingData.length">
          <div class="error-icon missing"></div>
          <p class="error-header">No videos found</p>
          <p class="error-text">Please come back soon, we're working on adding new videos</p>
        </div>
      </ng-container>
    </ng-container>

    <mat-spinner *ngIf="showSpinner$ | async" style="margin: 0 auto; stroke: #358dff"></mat-spinner>
  </div>
</div>
