import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { Comment } from '../../../hosting-interfaces';
import { DatePipe, NgIf } from '@angular/common';
import { CommentOwnerPipe } from '../comment-owner.pipe';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from '@openreel/ui/or-avatar/avatar.module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { CommentAccessibilityPipe } from '../comment-accessibility.pipe';
import { RelativeDatePipe } from '@openreel/frontend/common/pipes/relative-date.pipe';
import { FormatDurationPipe } from '@openreel/frontend/common/pipes/format-duration.pipe';
import { ParseDurationPipe } from '@openreel/frontend/common/pipes/parse-duration.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

export type CommentTemplate = 'internal' | 'widget' | 'video-container';

@Component({
  selector: 'or-comment-list-item',
  templateUrl: './comment-list-item.component.html',
  styleUrls: ['./comment-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    DatePipe,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    CommentOwnerPipe,
    CommentAccessibilityPipe,
    RelativeDatePipe,
    FormatDurationPipe,
    ParseDurationPipe,
    AvatarModule,
    ReactiveFormsModule,
  ],
})
export class CommentListItemComponent {
  cdr = inject(ChangeDetectorRef);

  @Input() commentTemplate: CommentTemplate = 'internal';
  @Input() comment: Comment;
  @Input()
  get selected() {
    return this._selected;
  }
  set selected(val: boolean) {
    this._selected = val;
    if (val && !!this.commentElement?.nativeElement) {
      this.commentElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }
  private _selected = false;
  @Input() showAccessibility = false;

  @Output() deleteComment = new EventEmitter<Comment>();
  @Output() updateComment = new EventEmitter<Comment>();
  @Output() resolveComment = new EventEmitter<Comment>();
  @Output() clickComment = new EventEmitter<Comment>();
  @Output() clickUpdateComment = new EventEmitter<Comment>();

  @ViewChild('commentElement')
  commentElement: ElementRef<HTMLElement>;

  editMode = false;
  updatingComment: Comment | null = null;
  updateCommentForm = new FormGroup({
    comment: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(200)]),
  });

  onDeleteComment(event: Event, comment: Comment): void {
    event.preventDefault();
    event.stopPropagation();
    this.deleteComment.emit(comment);
  }

  onResolveComment(event: Event, comment: Comment): void {
    event.preventDefault();
    event.stopPropagation();
    this.resolveComment.emit({ ...comment, isResolved: !comment.isResolved });
  }

  onChangeAccessibility(comment: Comment, event: MatSelectChange) {
    this.updateComment.emit({ ...comment, accessibility: event.value });
  }

  onUpdateComment(event: Event, comment: Comment): void {
    event.preventDefault();
    event.stopPropagation();
    this.clickUpdateComment.emit(comment);

    this.updatingComment = comment;
    this.editMode = true;
    this.updateCommentForm.reset({
      comment: comment.comment,
    });
    this.cdr.detectChanges();
  }

  onClickComment(event: Event, comment: Comment): void {
    event.preventDefault();
    event.stopPropagation();
    this.clickComment.emit(comment);
  }

  closeEditMode() {
    this.editMode = false;
    this.updatingComment = null;
    this.updateCommentForm.reset({
      comment: '',
    });
    this.cdr.detectChanges();
  }

  confirmUpdate() {
    if (this.updateCommentForm.valid) {
      this.updateComment.next({ ...this.updatingComment, comment: this.updateCommentForm.controls.comment.value });
      this.closeEditMode();
    }
  }

  stopPropagation(e: Event) {
    e.stopPropagation();
  }
}
