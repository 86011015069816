import { KtdGridLayout, KtdGridLayoutItem } from '@katoid/angular-grid-layout';
import {
  HostableLayout,
  HostableLayoutConfiguration,
  Widget,
  WidgetContraints,
  WidgetName,
} from '../../hosting-interfaces';

export namespace HostableLayoutUtils {
  const defaultWidgetData: Record<WidgetName, WidgetContraints> = {
    [WidgetName.Banner]: { leftCol: 0, columnWidth: 6, rowStart: 0, rowHeight: 17, minHeight: 8 },
    [WidgetName.VideoPlayer]: { leftCol: 0, columnWidth: 4, rowStart: 12, rowHeight: 70 },
    [WidgetName.Comments]: { leftCol: 0, columnWidth: 4, rowStart: 92, rowHeight: 55, minHeight: 25 },
    [WidgetName.ContactUs]: { leftCol: 4, columnWidth: 2, rowStart: 12, rowHeight: 57, maxHeight: 57, minHeight: 57 },
    [WidgetName.Chapters]: { leftCol: 4, columnWidth: 2, rowStart: 67, rowHeight: 35, minHeight: 25 },
    [WidgetName.Playlist]: { leftCol: 4, columnWidth: 2, rowStart: 102, rowHeight: 55, minHeight: 25 },
  };

  const _generateKtdGridLayoutItem = (widgetName: WidgetName, constraints: WidgetContraints): KtdGridLayoutItem => {
    const maxHeight = constraints.maxHeight ?? defaultWidgetData[widgetName].maxHeight;
    const minHeight = constraints.minHeight ?? defaultWidgetData[widgetName].minHeight;
    return {
      id: widgetName,
      x: constraints.leftCol,
      y: constraints.rowStart,
      w: constraints.columnWidth,
      h: _getGridLayoutItemSize(constraints.rowHeight, minHeight, maxHeight),
      maxH: constraints.maxHeight ?? defaultWidgetData[widgetName].maxHeight,
      minH: constraints.minHeight ?? defaultWidgetData[widgetName].minHeight,
    };
  };

  const _getGridLayoutItemSize = (currentSize: number, min?: number, max?: number) => {
    if (!!min && currentSize < min) return min;
    if (!!max && currentSize > max) return max;
    return currentSize;
  };

  export const getDefaultGridLayoutItem = (widgetName: WidgetName): KtdGridLayoutItem => {
    return _generateKtdGridLayoutItem(widgetName, defaultWidgetData[widgetName]);
  };

  export const mapLayoutConfigToGridLayoutItem = (config: HostableLayoutConfiguration): KtdGridLayoutItem => {
    return _generateKtdGridLayoutItem(config.widgetData.name, config);
  };

  export const mapLayoutConfigsToGridLayout = (configs: HostableLayoutConfiguration[]): KtdGridLayout => {
    return configs.map((config) => mapLayoutConfigToGridLayoutItem(config));
  };

  export const getLayoutWidgets = (layout: Partial<HostableLayout>): Widget[] => {
    return layout?.configurations?.map((config) => config.widgetData) ?? [];
  };

  export const getLayoutConstraints = (layout: Partial<HostableLayout>): KtdGridLayout => {
    return mapLayoutConfigsToGridLayout(layout?.configurations ?? []);
  };

  export function sortGridLayout(layout: KtdGridLayout): KtdGridLayout {
    const _layout = [...layout];
    _layout.sort((c1, c2) => (c1.y == c2.y ? c1.x - c2.x : c1.y - c2.y));
    return _layout;
  }

  //Works for 6 columns layout
  export function generateResponsiveGridLayout(columns: number, defaultLayout: KtdGridLayout): KtdGridLayout {
    if (columns == 6) return defaultLayout;

    const sortedLayout = sortGridLayout(defaultLayout);

    return sortedLayout.map((c) => ({ ...c, x: 0, y: c.y + 1, w: columns }));

    // return sortedLayout.map(c => {
    //     let { x, y, w } = c;
    //     if(w > columns) w = columns;
    //     if(x >= columns) {
    //         x = x - columns;
    //         y = y + 1
    //     }
    //     if(x + w > columns) {
    //         x = columns - w;
    //         y = y + 1;
    //     }
    //     return ({...c, x, y, w})
    // })
  }
}
