import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AnonymousUserData, CreatePublicCommentDTO } from '@openreel/frontend/common/hosting/hosting-interfaces';
import { AuthService } from '../../services/auth.service';
import { PublicCommentsManagerService } from '../public-comments-manager.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AnonymousUserDialogComponent } from '../anonymous-user-dialog/anonymous-user-dialog.component';
import { Observable, filter, take } from 'rxjs';
import { LayoutManagerService } from '../../video-container/layout-manager.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { StartTextInputDirective } from '../../analytics/start-text-input.directive';
import { AvatarModule } from '@openreel/ui/or-avatar/avatar.module';

@Component({
  selector: 'openreel-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    NgIf,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    StartTextInputDirective,
    AvatarModule,
  ],
})
export class AddCommentComponent {
  @Output() startComment = new EventEmitter<void>();
  @Output() submitComment = new EventEmitter<void>();

  cdr = inject(ChangeDetectorRef);
  authService = inject(AuthService);
  commentsManager = inject(PublicCommentsManagerService);
  dialog = inject(MatDialog);
  layoutManager = inject(LayoutManagerService, { optional: true });

  showCommentForm: boolean = false;
  addCommentForm = new FormGroup({
    comment: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(200)]),
    anonymousUserInfo: new FormControl<AnonymousUserData | null>(null),
  });

  currentUser$ = this.authService.user$;

  leaveComment(): void {
    this.showCommentForm = true;
    this.cdr.detectChanges();
    this.layoutManager?.resizeVideoContainer();
  }

  onSubmitComment(): void {
    if (this.addCommentForm.valid) {
      if (!this.authService.user) {
        this._openAnonymousUserDialog()
          .pipe(
            filter((userInfo) => !!userInfo),
            take(1),
          )
          .subscribe((userInfo) => {
            this.addCommentForm.controls.anonymousUserInfo.setValue(userInfo);
            this._addComment();
          });
      } else {
        this._addComment();
      }
    }
  }

  cancel(): void {
    this.showCommentForm = false;
    this._resetCommentForm();
    this.cdr.detectChanges();
    this.layoutManager?.resizeVideoContainer();
  }

  logout() {
    this.authService.resetActiveSession();
  }

  private _openAnonymousUserDialog(): Observable<AnonymousUserData | null> {
    return this.dialog
      .open(AnonymousUserDialogComponent, {
        width: '480px',
      })
      .afterClosed();
  }

  private _addComment(): void {
    this.commentsManager.addPublicComment(this.addCommentForm.value as CreatePublicCommentDTO);
    this.showCommentForm = false;
    this._resetCommentForm();
    this.cdr.detectChanges();
    this.layoutManager?.resizeVideoContainer();
    this.submitComment.emit();
  }

  private _resetCommentForm() {
    this.addCommentForm.reset({
      comment: '',
      anonymousUserInfo: null,
    });
  }
}
