export interface NumberRange {
  from: number;
  to: number;
}

export interface TimeAndIndexRange {
  fromTime: number;
  toTime: number;
  fromIndex: number;
  toIndex: number;
}
