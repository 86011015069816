<ng-container openreelInitAnalytics="PUBLIC Analytics: ">
  <openreel-hosting-player
    class="cornered-player"
    *ngIf="srcData$ | async as data"
    (playerReady)="onPlayerReady($event)"
    (videoEvent)="onVideoEvent($event)"
    [srcData]="data"
    [autoplay]="data.autoplay"
    [mute]="data.mute"
    [openreelHostingPlayerEventCapture]="data"
  ></openreel-hosting-player>
</ng-container>
