import moment, { Duration } from 'moment';

export interface DurationFormatOptions {
  displayMilliseconds?: boolean;
  forceDisplayHours?: boolean;
}

export namespace DurationUtils {
  export const isValidDurationString = (durationStr: string): boolean => {
    return moment(durationStr, ['HH:mm:ss', 'mm:ss'], true).isValid();
  };

  export const parseDuration = (value: number | string): Duration | null => {
    switch (typeof value) {
      case 'string':
        return _parseDurationFromString(value);
      case 'number':
        return _parseDurationFromSeconds(value);
      default:
        return null;
    }
  };

  const _parseDurationFromSeconds = (seconds: number): Duration => {
    return moment.duration(seconds, 's');
  };

  const _parseDurationFromString = (durationStr: string): Duration | null => {
    const date = moment(durationStr, ['HH:mm:ss', 'mm:ss'], true);
    if (!date.isValid()) return null;

    return moment.duration({
      hours: date.hour(),
      minutes: date.minute(),
      seconds: date.second(),
    });
  };

  export const formatDuration = (duration: Duration, options?: DurationFormatOptions): string => {
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const ms = duration.milliseconds();

    let result = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    if (options?.forceDisplayHours || hours > 0) result = `${String(hours).padStart(2, '0')}:${result}`;
    if (options?.displayMilliseconds) result += `.${String(ms).padStart(3, '0')}`;

    return result;
  };

  export const compareDurations = (duration1: Duration, duration2: Duration): -1 | 0 | 1 => {
    const s1 = duration1.asSeconds();
    const s2 = duration2.asSeconds();
    if (s1 < s2) return -1;
    if (s1 == s2) return 0;
    return 1;
  };
}
