import { NumberUtils } from './../../../../number.utils';
import { DurationUtils } from './../../../../duration.utils';

import videojs from 'video.js';
import { Duration } from 'moment';
const Plugin = videojs.getPlugin('plugin');

export interface SeekbarChaptersOptions {
  chapters: Array<{ start: number; name: string }>;
  hideChaptersButton?: boolean;
  backgroundColor?: string;
}

export class SeekbarChaptersPlugin extends Plugin {
  private _player: videojs.Player;
  private _options: SeekbarChaptersOptions;

  private _seekbar?: videojs.Component;
  private _chaptersContainer?: Element;

  private _durationAsSecs: number = 0;
  private _duration: Duration | null = null;

  private _unsubscribers: Array<() => void> = [];

  constructor(player: videojs.Player, options: SeekbarChaptersOptions) {
    super(player, options);
    this._player = player;
    this._options = options;
    if (options.chapters && options.chapters.length) {
      this._getDurationAndControls(() => {
        this._makeChapters();
        this._listenEvents();
      });
    }
  }

  private _getDurationAndControls(onReadyCallback: () => void) {
    const _loadedmetadataEventListener = () => {
      this._durationAsSecs = this._player.duration();
      this._duration = DurationUtils.parseDuration(this._durationAsSecs);
      const controlBar = this._player.getChild('ControlBar');
      const progressControl = controlBar?.getChild('ProgressControl');
      this._seekbar = progressControl?.getChild('SeekBar');
      onReadyCallback();
    };

    this._player.on('loadedmetadata', _loadedmetadataEventListener);

    const unsub = () => this._player.off('loadedmetadata', _loadedmetadataEventListener);
    this._unsubscribers.push(unsub);
  }

  private _makeChaptersButton(chapters: Array<{ start: number; name: string }>) {
    const vttFileUrl = this._makeVttFileFromChapters(chapters);
    const trackElement = this.player.addRemoteTextTrack(
      {
        kind: 'chapters',
        src: vttFileUrl,
        srclang: 'en',
        default: true,
      },
      false,
    );

    trackElement.addEventListener('load', () => {
      const element = this.player.controlBar
        .getChild('ChaptersButton')
        ?.el()
        ?.getElementsByClassName('vjs-menu-content');
      if (element && element.length > 0)
        element[0].setAttribute('style', `background-color: ${this._options.backgroundColor}`);
    });

    const unsub = () => window.URL.revokeObjectURL(vttFileUrl);
    this._unsubscribers.push(unsub);
  }

  private _makeVttFileFromChapters(chapters: Array<{ start: number; name: string }>) {
    const duration = Math.floor(this._durationAsSecs);
    let vttStr = 'WEBVTT\n\n';

    chapters.forEach((chapter, index, arr) => {
      const start = arr[index].start;
      const end = !!arr[index + 1]?.start ? arr[index + 1].start - 1 : duration;

      vttStr +=
        `${index + 1}\n` +
        `${DurationUtils.formatDuration(DurationUtils.parseDuration(start), { displayMilliseconds: true, forceDisplayHours: true })}` +
        ' --> ' +
        `${DurationUtils.formatDuration(DurationUtils.parseDuration(end), { displayMilliseconds: true, forceDisplayHours: true })}\n` +
        `${chapter.name} (${DurationUtils.formatDuration(DurationUtils.parseDuration(start), { forceDisplayHours: this._duration.hours() > 0 })})\n\n`;
    });

    const vttFile = new Blob([vttStr], { type: 'text/vtt' });
    return window.URL.createObjectURL(vttFile);
  }

  private _makeSeekbarChapters(chapters: Array<{ start: number; name: string }>) {
    this._seekbar.setAttribute('style', 'background: rgba(0,0,0,0.8)');

    this._chaptersContainer = videojs.dom.createEl(
      'div',
      {},
      { style: '--vjs-play-progress: 0;', class: 'vjs-seekbar-chapters-container' },
      null,
    );

    chapters.forEach((item, index, arr) => {
      const end = index === arr.length - 1 ? this._durationAsSecs : arr[index + 1].start;
      const start = item.start;

      const startPos = NumberUtils.toFixedNum((start / this._durationAsSecs) * 100);
      const endPos = NumberUtils.toFixedNum((end / this._durationAsSecs) * 100);
      const width = NumberUtils.toFixedNum(endPos - startPos);

      const chapterTooltip = videojs.dom.createEl(
        'span',
        {},
        { class: 'vjs-seekbar-chapter-tooltip' },
        `${item.name} (${DurationUtils.formatDuration(DurationUtils.parseDuration(item.start), { forceDisplayHours: this._duration.hours() > 0 })})`,
      );
      chapterTooltip.setAttribute('style', `background-color: ${this._options.backgroundColor}`);
      const chapterPlayProgress = videojs.dom.createEl('div', {}, { class: 'vjs-seekbar-chapter-play-progress' }, null);
      const chapterProgressContainer = videojs.dom.createEl(
        'div',
        {},
        { class: 'vjs-seekbar-chapter-progress-container' },
        null,
      );
      const chapterContainer = videojs.dom.createEl(
        'div',
        {},
        {
          class: 'vjs-seekbar-chapter',
          style: `--vjs-chapter-start: ${startPos}; --vjs-chapter-end: ${endPos}; --vjs-chapter-width: ${width};`,
        },
        null,
      );

      chapterProgressContainer.append(chapterPlayProgress);
      chapterContainer.append(chapterProgressContainer);
      // chapterContainer.append(chapterDivider);
      chapterContainer.append(chapterTooltip);
      this._chaptersContainer?.append(chapterContainer);
    });

    this._seekbar!.el().append(this._chaptersContainer);
  }

  private _makeChapters() {
    const { chapters } = this._options;
    const sortedChapters = chapters.sort((a, b) => a.start - b.start);

    if (!this._options.hideChaptersButton) this._makeChaptersButton(sortedChapters);
    if (this._seekbar) this._makeSeekbarChapters(sortedChapters);
  }

  private _listenEvents() {
    const _timeUpdateListener = (e, f) => {
      const currentPosition = NumberUtils.toFixedNum((this._player.currentTime() / this._durationAsSecs) * 100);
      this._chaptersContainer?.setAttribute('style', `--vjs-play-progress: ${currentPosition};`);
    };

    this._player.on('timeupdate', _timeUpdateListener);
    const unsub = () => this._player.off('timeupdate', _timeUpdateListener);

    this._unsubscribers.push(unsub);
  }

  dispose(): void {
    super.dispose();
    this._unsubscribers.forEach((unsub) => unsub());
  }
}

videojs.registerPlugin('seekbarChapters', SeekbarChaptersPlugin);

declare module 'video.js' {
  export interface VideoJsPlayer {
    seekbarChapters: (options?: SeekbarChaptersOptions) => SeekbarChaptersPlugin;
  }

  export interface VideoJsPlayerPluginOptions {
    seekbarChapters?: SeekbarChaptersOptions;
  }
}
