<div class="comments-list" *ngIf="comments?.length">
  <ng-container *ngFor="let comment of comments">
    <div *ngIf="!comment" class="deleted-comment">
      <p>Comment deleted</p>
    </div>
    <or-comment-list-item
      *ngIf="!!comment"
      [commentTemplate]="commentTemplate"
      [comment]="comment"
      [selected]="comment.id == selectedComment?.id"
      [showAccessibility]="showAccessibility"
      (deleteComment)="deleteComment.emit($event)"
      (updateComment)="updateComment.emit($event)"
      (resolveComment)="resolveComment.emit($event)"
      (clickComment)="clickComment.emit($event)"
      (clickUpdateComment)="clickUpdateComment.emit($event)"
    ></or-comment-list-item>
  </ng-container>
  <div>
    <button
      class="load-more-btn"
      *ngIf="!isAllCommentsLoaded && !isLoadingComments"
      mat-stroked-button
      color="primary"
      (click)="onLoadMoreComments()"
    >
      Load more comments
    </button>
  </div>
</div>

<mat-spinner *ngIf="isLoadingComments" [diameter]="48"></mat-spinner>

<ng-container *ngIf="!comments?.length && !isLoadingComments">
  <div class="empty-template-container">
    <openreel-logo size="90px" color="#D8DAE5">
      <mat-icon>chat</mat-icon>
    </openreel-logo>
    <p>No Comments yet</p>
    <p>Leave a comment and it will be shown here</p>
  </div>
</ng-container>
