import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ImageExtractorService {
  private extractImgSource = new Subject();
  extractImage$ = this.extractImgSource.asObservable();
  extractImage() {
    this.extractImgSource.next(null);
  }

  private extractedImgSource = new Subject<[Blob, number]>();
  extractedImage$ = this.extractedImgSource.asObservable();
  extractedImage(img: Blob, timestamp: number) {
    this.extractedImgSource.next([img, timestamp]);
  }

  private toggleExtractModSource = new Subject<boolean>();
  toggleExtraceMode$ = this.toggleExtractModSource.asObservable();
  toggleExtractMode(on: boolean) {
    this.toggleExtractModSource.next(on);
  }
}
