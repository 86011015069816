<button mat-button class="dialog-close-button" (click)="cancel()">
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>Add your email/name to comment</h1>
<div mat-dialog-content>
  <form id="userForm" [formGroup]="userForm" (ngSubmit)="saveChanges()">
    <label class="form-label">Name or Alias</label>
    <mat-form-field class="form-control" appearance="outline">
      <input matInput formControlName="fullname" placeholder="Enter here" />
      <mat-error *ngIf="userForm.controls.fullname.hasError('maxlength')" class="error-message">
        Fullname cannot be more than {{ userForm.controls.fullname.getError('maxlength')['requiredLength'] }} characters
        long
      </mat-error>
    </mat-form-field>

    <label class="form-label">Email address</label>
    <mat-form-field class="form-control" appearance="outline">
      <input matInput formControlName="email" placeholder="Enter here" />
      <mat-error *ngIf="userForm.controls.email.hasError('invalidEmail')" class="error-message">
        Invalid email address.
      </mat-error>
      <mat-error *ngIf="userForm.controls.email.hasError('maxlength')" class="error-message">
        Email cannot be more than {{ userForm.controls.email.getError('maxlength')['requiredLength'] }} characters long
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button type="submit" form="userForm" mat-flat-button color="primary">Save changes</button>
</div>
