import { isPlatformBrowser } from '@angular/common';
import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';

import { GoogleTagManagerService } from './services/google-tag-manager.service';

@Component({
  standalone: false,
  selector: 'openreel-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly googleTagManagerService = inject(GoogleTagManagerService);
  private readonly platformId = inject(PLATFORM_ID);

  ngOnInit(): void {
    this.initializeGoogleTagManagerIntegration();
  }

  private initializeGoogleTagManagerIntegration(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.googleTagManagerService.requestGtmCode();
    }
  }
}
