import videojs, { VideoJsPlayer } from 'video.js';
import { BACK_BUTTON_SVG, QUALITY_ICON_SVG } from './constants';
import {
  PlayerPlaybackQualityMenuItemOptions,
  PlayerPlaybackQualitySubmenuOptions,
} from './player-settings-plugin.interface';

const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

export class PlaybackQualityMenuItem extends Button {
  private readonly _player: VideoJsPlayer;
  private readonly _options: PlayerPlaybackQualityMenuItemOptions;

  constructor(player: VideoJsPlayer, options: PlayerPlaybackQualityMenuItemOptions) {
    super(player);

    this._player = player;
    this._options = options;

    this.initialise();
  }

  initialise(): void {
    this.contentEl().querySelector('.vjs-icon-placeholder').remove();
    this.contentEl().querySelector('.vjs-control-text').remove();

    this.setCurrentQualityLabel(this._player.qualityLevel || 'Auto');
  }

  handleClick(): void {
    this._options.onClick();
  }

  buildCSSClass(): string {
    return `vjs-settings-overlay-menu-item vjs-settings-button vjs-playback-quality-menu-item ${super.buildCSSClass()}`;
  }

  createEl(): HTMLButtonElement {
    return super.createEl('button', {
      innerHTML: `
          <div class="vjs-settings-overlay-menu-item-icon">${QUALITY_ICON_SVG}</div>

          <div class="vjs-settings-overlay-menu-item-label">
            <span>Quality</span>
            <span id="vjs-settings-overlay-quality" class="vjs-settings-overlay-quality"></span>
          </div>
        `,
    });
  }

  setCurrentQualityLabel(quality: string): void {
    const qualityLabelElement = this.contentEl().querySelector('#vjs-settings-overlay-quality');

    if (qualityLabelElement) {
      qualityLabelElement.textContent = quality;
    }
  }
}

export class QualityPickerSubmenu extends Component {
  private readonly _options: PlayerPlaybackQualitySubmenuOptions;

  constructor(player: VideoJsPlayer, options: PlayerPlaybackQualitySubmenuOptions) {
    super(player);

    this._options = options;

    this.render();
  }

  createEl(): Element {
    return super.createEl('div', {
      className: 'vjs-settings-overlay-menu vjs-settings-overlay-menu-quality-picker',
    });
  }

  render(): void {
    this.renderBackButton();
    this.renderOptionsList();
  }

  renderBackButton(): void {
    const backButton = videojs.dom.createEl('button', {
      className: 'vjs-settings-overlay-menu-item vjs-settings-overlay-menu-item-back',
      innerHTML: `
        ${BACK_BUTTON_SVG}

        <span class="vjs-settings-overlay-menu-item-label">Back</span>
      `,
    });

    backButton.addEventListener('click', () => {
      this._options.onClose(null);
      this.dispose();
    });

    this.el().appendChild(backButton);
  }

  renderOptionsList(): void {
    const list = videojs.dom.createEl('div', {
      className: 'vjs-settings-overlay-menu-list vjs-settings-overlay-menu-list-quality-picker',
    });

    const qualityLevels = this._options.playbackQuality.qualityLevels;

    qualityLevels.forEach((qualityLevel) => {
      const item = videojs.dom.createEl('button', {
        className: 'vjs-settings-overlay-menu-list-item vjs-settings-overlay-menu-item',
        innerHTML: `
          <span class="vjs-settings-overlay-menu-list-item-label">${qualityLevel.label}</span>
        `,
      });

      item.addEventListener('click', () => {
        const currentTime = this.player().currentTime();
        const isPlaying = !this.player().paused();
        const playbackRate = this.player().playbackRate();
        const textTracks = this.player().textTracks();
        let activeTextTrackId: string | null = null;

        for (let i = 0; i < textTracks.length; i++) {
          if (textTracks[i].mode === 'showing') {
            activeTextTrackId = textTracks[i].id;
          }
        }

        this.player().src(qualityLevel.srcUrl);
        this.player().qualityLevel = qualityLevel.label;

        this.player().ready(() => {
          this.player().playbackRate(playbackRate);
          this.player().currentTime(currentTime);

          if (isPlaying) {
            this.player().play();
          }
        });

        const addTrackCallback = () => {
          if (!activeTextTrackId) {
            return;
          }

          const track = this.player().textTracks().getTrackById(activeTextTrackId);

          if (track) {
            track.mode = 'showing';
          }

          this.player().off('loadedmetadata', addTrackCallback);
        };

        this.player().on('loadedmetadata', addTrackCallback);

        this._options.onClose(qualityLevel.label);
      });

      list.appendChild(item);
    });

    this.el().appendChild(list);
  }
}

videojs.registerComponent('PlaybackQualityMenuItem', PlaybackQualityMenuItem);
videojs.registerComponent('PlaybackQualitySubmenu', QualityPickerSubmenu);
