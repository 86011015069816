<div *ngIf="srcData$ | async as data" class="page-container">
  <ng-template #playerWidgetTemplate>
    <div class="video-container">
      <openreel-hosting-player
        #hostingPlayer
        *orRecreateOnChange="data"
        [srcData]="data"
        [openreelHostingPlayerEventCapture]="data"
        class="cornered-player"
      ></openreel-hosting-player>
    </div>
    <div>
      <div class="video-info">
        <div class="video-title" *ngIf="brandkitLoaded$ | async">{{ data.title }}</div>
        <div *ngIf="(isAvailablePublicComments$ | async) && !data.commentsDisabled" class="comments-count-box">
          <mat-icon color="primary">chat</mat-icon>
          <span>{{ commentsCount$ | async | pluralize: 'comment' : 'comments' }}</span>
        </div>
      </div>
      <div class="video-details" *ngIf="data.appearance.description">
        <div class="video-description">{{ data.description }}</div>
      </div>
    </div>

    <div *ngIf="(isAvailablePublicComments$ | async) && !data.commentsDisabled" class="add-comment-section">
      <openreel-add-comment
        (startComment)="logStartCommentEvent()"
        (submitComment)="logSubmitCommentEvent()"
      ></openreel-add-comment>
      <div *ngIf="!(showCommentsWidget$ | async) && (commentsCount$ | async)" class="video-container-comments">
        <ng-container *ngTemplateOutlet="commentList; context: { commentTemplate: 'video-container' }"></ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #commentList let-commentTemplate="commentTemplate">
    <openreel-public-comment-list
      [commentTemplate]="commentTemplate"
      [videoToken]="videoToken$ | async"
      (clickDeleteComment)="logDeleteCommentEvent()"
      (clickUpdateComment)="logUpdateCommentEvent()"
    ></openreel-public-comment-list>
  </ng-template>

  <ng-container *ngIf="layoutData$ | async as layoutData">
    <div *ngIf="!layoutData.widgets?.length; else gridLayoutTemplate" class="video-wrapper player-default-container">
      <ng-container *ngTemplateOutlet="playerWidgetTemplate"></ng-container>
    </div>

    <ng-template #gridLayoutTemplate>
      <div class="grid-layout-container" *ngIf="isBrowser">
        <or-hostable-grid-layout
          #hostableGridLayout
          [layout]="layoutData?.constraints"
          [widgets]="layoutData?.widgets"
          [contentTemplate]="contentTemplate"
        >
          <ng-template #contentTemplate let-widget="widget">
            <or-banner-widget-layout
              *ngIf="widget?.name == WidgetName.Banner"
              [brandKit]="data?.brandKit"
              [widgetData]="widget?.customData"
            ></or-banner-widget-layout>

            <or-chapters-widget-layout
              *ngIf="widget?.name == WidgetName.Chapters"
              [chapters]="data?.chapters"
              [forceDisplayHours]="true"
              (clickChapter)="clickChapter($event)"
            ></or-chapters-widget-layout>

            <ng-container *ngIf="widget?.name == WidgetName.Comments">
              <or-widget-layout
                *ngIf="(isAvailablePublicComments$ | async) && (showCommentsWidget$ | async)"
                [widgetName]="WidgetName.Comments"
                [isEnableConfigureMode]="false"
              >
                <ng-container *ngIf="!data.commentsDisabled; else commentsDisabledTemplate" widgetContent>
                  <ng-container
                    *ngTemplateOutlet="commentList; context: { commentTemplate: 'video-container' }"
                  ></ng-container>
                </ng-container>

                <ng-template #commentsDisabledTemplate>
                  <p class="comments-disabled-text">Comments are turned off for this video.</p>
                </ng-template>
              </or-widget-layout>
            </ng-container>

            <ng-container *ngIf="widget?.name == WidgetName.ContactUs">
              <or-contactus-widget-layout
                *orRecreateOnChange="layoutData?.layoutId"
                [layoutId]="layoutData?.layoutId"
                [widgetData]="widget?.customData"
              ></or-contactus-widget-layout>
            </ng-container>

            <or-playlist-widget-layout
              *ngIf="widget?.name == WidgetName.Playlist"
              [videos]="playlistVideos$ | async"
              [total]="playlistVideosTotal$ | async"
              [pageSize]="playlistVideosPageSize$ | async"
              [pageIndex]="playlistVideosPageIndex$ | async"
              [loading]="playlistVideosLoading$ | async"
              (clickVideo)="onClickVideo($event)"
              (loadMore)="loadMorePlaylistVideos()"
            ></or-playlist-widget-layout>

            <div *ngIf="widget?.name == WidgetName.VideoPlayer" class="video-wrapper player-grid-layout-container">
              <ng-container *ngTemplateOutlet="playerWidgetTemplate"></ng-container>
            </div>
          </ng-template>
        </or-hostable-grid-layout>
      </div>
    </ng-template>
  </ng-container>
</div>
