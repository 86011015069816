import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { commonenv } from '../environments/environment';
import { CommentableType } from '../hosting/constants';
import {
  CreateInternalCommentDTO,
  Comment,
  InternalCommentsQueryDTO,
  UpdateInternalCommentDTO,
  ResolveInternalCommentDTO,
} from '../hosting/hosting-interfaces';

@Injectable({
  providedIn: 'root',
})
export class InternalCommentsService {
  private baseUrl = commonenv.nextGenApiUrl;

  constructor(private http: HttpClient) {}

  fetchComments(token: string, queryOptions: InternalCommentsQueryDTO): Observable<{ count: number; rows: Comment[] }> {
    const params = new HttpParams()
      .append('page', queryOptions.page)
      .append('orderField', queryOptions.orderField)
      .append('includeRelated', queryOptions.includeRelated)
      .append('token', token);

    return this.http.get<{ count: number; rows: Comment[] }>(`${this.baseUrl}comment`, { params: params });
  }

  addComment(dto: CreateInternalCommentDTO): Observable<Comment> {
    return this.http.post<Comment>(`${this.baseUrl}comment`, dto);
  }

  updateComment(commentId: number, dto: UpdateInternalCommentDTO): Observable<Comment> {
    return this.http.put<Comment>(`${this.baseUrl}comment/${commentId}`, dto);
  }

  resolveComment(commentId: number, dto: ResolveInternalCommentDTO): Observable<Comment> {
    return this.http.put<Comment>(`${this.baseUrl}comment/${commentId}/resolve`, dto);
  }

  deleteComment(commentId: number | string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}comment/${commentId}`);
  }

  //Not integrated with the api
  updateCommentsStatus(videoId: number, status: boolean): Observable<{ result: string; data: any }> {
    return of({ result: 'success', data: status }).pipe(delay(2000));
    // return this.http.put<any>(`${this.baseUrl}hosting/videos/${videoId}/video-comment/status`, { status });
  }
}
