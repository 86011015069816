import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BannerwidgetLayoutComponent } from './banner-widget-layout.component';

@NgModule({
  declarations: [BannerwidgetLayoutComponent],
  imports: [CommonModule],
  exports: [BannerwidgetLayoutComponent],
})
export class BannerWidgetLayoutModule {}
