import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { SeekbarCommentsManager } from '../hosting/video-player/player/seekbar-comments/seekbar-comments.plugin';
import { InternalCommentsService } from './internal-comments.service';
import { CommentableSortOptions, CommentableType } from '../hosting/constants';
import { CreateInternalCommentDTO, Comment } from '../hosting/hosting-interfaces';
import {
  DrawableShapesCreatorManager,
  DrawableShapesViewerManager,
} from '../drawable-container/resources/drawable.types';
import { DrawableAreaData, DrawableShapeColor, DrawableShapeType, isNullOrUndefined } from '@openreel/common';

interface DrawModeState {
  isActive: boolean;
  selectedShape: DrawableShapeType;
  selectedColor: DrawableShapeColor;
  drawnShapesData: DrawableAreaData | null;
}

export interface InternalCommentsState {
  commentableType: CommentableType | null;
  includeRelatedComments: boolean;
  isEnable: boolean;
  sortOption: CommentableSortOptions;
  page: number;
  commentsTotal: number;
  comments: Array<Comment | null>;
  selectedComment: Comment | null;
  playerCurrentTime: number;
  isLoading: boolean;
  error: any;
  drawMode: DrawModeState;
  token: string | null;
}

const DRAW_MODE_INITIAL_STATE: DrawModeState = {
  isActive: false,
  selectedShape: DrawableShapeType.Arrow,
  selectedColor: DrawableShapeColor.White,
  drawnShapesData: null,
};

const INITIAL_STATE: InternalCommentsState = {
  commentableType: null,
  includeRelatedComments: true,
  isEnable: true,
  sortOption: 'createdAt',
  page: -1,
  commentsTotal: -1,
  comments: [],
  selectedComment: null,
  playerCurrentTime: 0,
  isLoading: false,
  error: null,
  drawMode: DRAW_MODE_INITIAL_STATE,
  token: null,
};

@Injectable()
export class InternalCommentsManagerService
  extends ComponentStore<InternalCommentsState>
  implements SeekbarCommentsManager, DrawableShapesCreatorManager, DrawableShapesViewerManager
{
  constructor(
    private internalCommentsService: InternalCommentsService,
    private toastr: ToastrService,
  ) {
    super(INITIAL_STATE);
  }

  public readonly isEnable$ = this.select((state) => state.isEnable);
  public readonly sortOption$ = this.select((state) => state.sortOption);
  public readonly page$ = this.select((state) => state.page);
  public readonly comments$ = this.select((state) => state.comments);
  public readonly commentsCount$ = this.select((state) => state.commentsTotal);
  public readonly isAllCommentsLoaded$ = this.select(
    this.comments$,
    this.commentsCount$,
    (comments, count) => comments.length >= count,
  );
  public readonly timecodedComments$ = this.select(this.comments$, (comments) =>
    comments.filter((comment) => !isNullOrUndefined(comment?.timecodeMs)),
  );
  public readonly selectedComment$ = this.select((state) => state.selectedComment);
  public readonly isLoading$ = this.select((state) => state.isLoading);
  public readonly commentableType$ = this.select((state) => state.commentableType);
  public readonly token$ = this.select((state) => state.token);
  public readonly includeRelatedComments$ = this.select((state) => state.includeRelatedComments);

  public readonly playerCurrentTime$ = this.select((state) => state.playerCurrentTime);

  public readonly isActiveDrawMode$ = this.select((state) => state.drawMode.isActive);
  public readonly drawableShapeColor$ = this.select((state) => state.drawMode.selectedColor);
  public readonly drawableShapeType$ = this.select((state) => state.drawMode.selectedShape);
  public readonly drawResult$ = this.select((state) => state.drawMode.drawnShapesData);
  public readonly drawableDataToView$ = this.select(
    this.selectedComment$,
    this.isActiveDrawMode$,
    (comment, isActive) => (!isActive ? comment?.annotation : null),
  );

  public readonly changeDrawModeActiveState = this.updater((state, isActive: boolean) => ({
    ...state,
    drawMode: { ...state.drawMode, isActive },
  }));

  public readonly changeDrawableShapeColor = this.updater((state, color: DrawableShapeColor) => ({
    ...state,
    drawMode: { ...state.drawMode, selectedColor: color },
  }));

  public readonly changeDrawableShapeType = this.updater((state, shape: DrawableShapeType) => ({
    ...state,
    drawMode: { ...state.drawMode, selectedShape: shape },
  }));

  public readonly completeDraw = this.updater((state, data: DrawableAreaData) => ({
    ...state,
    drawMode: { ...state.drawMode, drawnShapesData: data },
  }));

  public readonly clearDrawResult = this.updater((state) => ({
    ...state,
    drawMode: { ...state.drawMode, drawnShapesData: null },
  }));

  public readonly setCommentsEnableState = this.updater((state, isEnable: boolean) => ({
    ...(isEnable ? state : INITIAL_STATE),
    isEnable,
  }));

  setPlayerCurrentTime(currentTime: number) {
    this.patchState({ playerCurrentTime: currentTime });
  }

  selectComment(comment: Comment | null): void {
    this.setState((state) => ({
      ...state,
      selectedComment: !comment || comment.id == state.selectedComment?.id ? null : comment,
    }));
  }

  setSortOption(sortOption: CommentableSortOptions): void {
    this.patchState((state) => {
      if (sortOption == state.sortOption) return state;
      return {
        sortOption: sortOption,
        page: -1,
        commentsTotal: -1,
        comments: [],
        selectedComment: null,
      };
    });
  }

  setToken(token: string | null, includeRelatedComments = true): void {
    this._resetComments({ token, includeRelatedComments });
  }

  public readonly fetchComments = this.effect((obs$: Observable<void>) =>
    obs$.pipe(
      withLatestFrom(this.token$, this.isEnable$, this.page$, this.sortOption$, this.includeRelatedComments$),
      filter(([_, token, isEnable, page, sort]) => !!token && isEnable),
      tap((_) => this.patchState({ isLoading: true })),
      switchMap(([_, token, isEnable, page, sort, includeRelatedComments]) =>
        this.internalCommentsService
          .fetchComments(token, { page: page + 1, orderField: sort, includeRelated: includeRelatedComments })
          .pipe(
            tapResponse((response) => {
              this.patchState((state) => ({
                isLoading: false,
                page: page + 1,
                comments: [...state.comments, ...response.rows],
                commentsTotal: response.count,
              }));
            }, this._handleError),
          ),
      ),
    ),
  );

  public readonly addComment = this.effect((obs$: Observable<CreateInternalCommentDTO>) =>
    obs$.pipe(
      mergeMap((dto) =>
        this.internalCommentsService.addComment(dto).pipe(
          tapResponse((response) => {
            this._handleSuccessResult('Comment added');
            this.patchState((state) => ({
              comments: [response, ...state.comments],
              commentsTotal: state.commentsTotal + 1,
            }));
          }, this._handleError),
        ),
      ),
    ),
  );

  public readonly deleteComment = this.effect((obs$: Observable<Comment>) =>
    obs$.pipe(
      mergeMap((comment) =>
        this.internalCommentsService.deleteComment(comment.id).pipe(
          tapResponse((response) => {
            this._handleSuccessResult('Comment removed');
            this.patchState((state) => {
              const comments = [...state.comments];
              comments[comments.findIndex((item) => item?.id === comment.id)] = null;
              return {
                comments: comments,
                selectedComment: state.selectedComment?.id == comment.id ? null : state.selectedComment,
              };
            });
          }, this._handleError),
        ),
      ),
    ),
  );

  public readonly updateComment = this.effect((obs$: Observable<Comment>) =>
    obs$.pipe(
      mergeMap((comment) =>
        this.internalCommentsService
          .updateComment(comment.id, { comment: comment.comment, accessibility: comment.accessibility })
          .pipe(
            tapResponse((response) => {
              this._handleSuccessResult('Comment updated');
              this.patchState((state) => {
                const comments = [...state.comments];
                const index = comments.findIndex((item) => item?.id === comment.id);
                comments[index] = response;
                return { comments: comments };
              });
            }, this._handleError),
          ),
      ),
    ),
  );

  public readonly resolveComment = this.effect((comment$: Observable<Comment>) =>
    comment$.pipe(
      distinctUntilChanged((prev, curr) => prev.id == curr.id && prev.isResolved == curr.isResolved),
      mergeMap((comment) =>
        this.internalCommentsService.resolveComment(comment.id, { isResolved: comment.isResolved }).pipe(
          tapResponse((response) => {
            this._handleSuccessResult(`Comment ${comment.isResolved ? 'Resolved' : 'Unresolved'}`);
            this.patchState((state) => {
              const comments = [...state.comments];
              const index = comments.findIndex((item) => item?.id === comment.id);
              comments[index] = response;
              return { comments: comments };
            });
          }, this._handleError),
        ),
      ),
    ),
  );

  private readonly _resetComments = this.effect(
    (obs$: Observable<{ token: string; includeRelatedComments: boolean }>) =>
      obs$.pipe(
        withLatestFrom(this.token$, this.includeRelatedComments$),
        filter(
          ([nextData, currentToken, currentIncludeRelated]) =>
            currentToken !== nextData.token || currentIncludeRelated !== nextData.includeRelatedComments,
        ),
        tap(([nextData]) => {
          this.patchState({
            token: nextData.token,
            includeRelatedComments: nextData.includeRelatedComments,
            comments: [],
            commentsTotal: -1,
            page: -1,
            selectedComment: null,
            drawMode: DRAW_MODE_INITIAL_STATE,
          });
          this.fetchComments();
        }),
      ),
  );

  private _handleError = (error: Error) => {
    this.patchState({ isLoading: false, error: error });
    this.toastr.error(error.message, 'Error!');
  };

  private _handleSuccessResult = (message: string) => {
    this.toastr.success(message, 'Success!');
  };
}
