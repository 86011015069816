import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommentListComponent } from '@openreel/frontend/common/hosting/components/comment-list/comment-list.component';
import { PublicCommentsManagerService } from '../public-comments-manager.service';
import { AsyncPipe } from '@angular/common';
import { combineLatest, map } from 'rxjs';
import { CommentTemplate } from '@openreel/frontend/common/hosting/components/comment-list/comment-list-item/comment-list-item.component';
import { Comment } from '@openreel/frontend/common/hosting/hosting-interfaces';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'openreel-public-comment-list',
  templateUrl: './public-comment-list.component.html',
  styleUrls: ['./public-comment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, CommentListComponent],
})
export class PublicCommentListComponent {
  commentsManager = inject(PublicCommentsManagerService);
  authService = inject(AuthService);

  @Input() commentTemplate: CommentTemplate = 'video-container';
  @Input()
  get videoToken() {
    return this._videoToken;
  }
  set videoToken(token: string) {
    if (!!token && this.videoToken != token) {
      this._videoToken = token;
      this.commentsManager.setVideoToken(token);
    }
  }
  private _videoToken: string;

  @Output() clickUpdateComment = new EventEmitter<Comment>();
  @Output() clickDeleteComment = new EventEmitter<Comment>();

  comments$ = this.commentsManager.comments$;

  canUpdateOrDeleteCommentFn$ = combineLatest([this.authService.user$, this.commentsManager.availableTokens$]).pipe(
    map(([userDetails, availableTokens]) => {
      return (comment: Comment) =>
        !!availableTokens[comment.id] || (!!userDetails && comment.createdBy?.id == userDetails?.data?.user_id);
    }),
  );

  onClickUpdateComment(comment: Comment) {
    this.clickUpdateComment.emit(comment);
  }

  onClickDeleteComment(comment: Comment) {
    this.clickDeleteComment.emit(comment);
    this.commentsManager.deletePublicComment(comment);
  }
}
