import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { debounceTime, Subject } from 'rxjs';

interface PageEvent {
  previousPageIndex: number;
  pageIndex: number;
  pageSize: number;
  length: number;
}

@Component({
  selector: 'or-infinite-scroll',
  template: '<ng-content></ng-content><div style="height: 3px;" #anchor></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class OrInfiniteScrollComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() options: IntersectionObserverInit = {};
  @Input() pageSize = 0;
  @Input() length = 0;

  @Input() set nextContentLoaded(isLoaded: boolean) {
    this.nextContentLoadedSubject.next(isLoaded);
  }

  private _pageIndex = 0;
  @Input()
  set pageIndex(i: number) {
    if (i !== this._pageIndex) {
      this._pageIndex = i;
      this.checkEmit();
    }
  }
  get pageIndex() {
    return this._pageIndex;
  }

  @Output() page = new EventEmitter<PageEvent>();
  @ViewChild('anchor') anchor: ElementRef<HTMLElement>;

  private previousPageIndex = 0;
  private _intersectingWithRoot = false;

  private observer: IntersectionObserver;
  private nextContentLoadedSubject = new Subject<boolean>();

  constructor(private host: ElementRef) {}

  ngOnInit(): void {
    this.subscribeToNextContentLoadedChanges();
  }

  private checkEmit() {
    if (this._intersectingWithRoot) {
      this.onNext();
    }
  }

  private hasNextPage() {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }

  private getNumberOfPages() {
    return !this.pageSize ? 0 : Math.ceil(this.length / this.pageSize);
  }

  onNext() {
    if (this.hasNextPage()) {
      this.previousPageIndex = this.pageIndex;
      this._pageIndex++;
      this.pageEmit();
    }
  }

  private pageEmit() {
    this.page.emit({
      previousPageIndex: this.previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }

  get element() {
    return this.host.nativeElement;
  }

  ngAfterViewInit() {
    const options = {
      root: null,
      ...this.options,
    };

    if (typeof IntersectionObserver === 'function') {
      this.observer = new IntersectionObserver(([entry]) => {
        this._intersectingWithRoot = entry.isIntersecting;
        this.checkEmit();
      }, options);

      this.observer.observe(this.anchor.nativeElement);
    }
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }

  private subscribeToNextContentLoadedChanges(): void {
    this.nextContentLoadedSubject
      .asObservable()
      .pipe(debounceTime(300))
      .subscribe((isLoaded) => {
        if (isLoaded) {
          this.checkEmit();
        }
      });
  }
}
