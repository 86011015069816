import { clone, cloneDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest, fromEvent, merge, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { OverlayPosition, OverlayPositionCoordinates, OverlaySize } from './overlay-position.interface';
import { OverlayTimeframe } from './overlay-timeframe.interface';
import { OVERLAY_POSITIONS } from './predefined-overlay-positions';
import { NumberUtils } from '../../../../number.utils';
import videojs, { VideoJsPlayer } from 'video.js';
import { CtaBrandKit, CtaFormModel } from './../../../hosting-interfaces';
import { CTA_FORM_DEFAULT_VALUE } from '../../../constants';

const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');
const ClickableComponent = videojs.getComponent('ClickableComponent');
const Plugin = videojs.getPlugin('plugin');

function addClass(component: videojs.Component, ...classesToAdd: string[]): void {
  classesToAdd.forEach((classToAdd) => {
    component.addClass(classToAdd);
  });
}

function removeClass(component: videojs.Component, ...classesToRemove: string[]): void {
  classesToRemove.forEach((classToAdd) => {
    component.removeClass(classToAdd);
  });
}

function adjustPosition(position: OverlayPositionCoordinates): OverlayPositionCoordinates {
  return {
    x: NumberUtils.toFixedNum(position.x, 3),
    y: NumberUtils.toFixedNum(position.y, 3),
  };
}

function setContrast(color: string) {
  const r = parseInt(color.slice(1, 3), 16),
    g = parseInt(color.slice(3, 5), 16),
    b = parseInt(color.slice(5, 7), 16);

  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
  const textColor = brightness > 125 ? 'black' : 'white';
  const backgroundColor = 'rgb(' + r + ',' + g + ',' + b + ')';

  return { textColor, backgroundColor };
}

const OVERLAY_CLASSES = OVERLAY_POSITIONS.map((overlayPosition) => overlayPosition.value);

interface OverlayDefinition {
  id?: number | null;
  buttonText: string;
  linkUrl: string;
  timeFrame: OverlayTimeframe;
  position: OverlayPosition;
  size?: OverlaySize;
}

interface OverlayOptions {
  overlays: OverlayDefinition[];
  brandKit: CtaBrandKit;
}
class OverlayPlugin extends Plugin {
  overlayButton: OverlayButton;

  private overlayButtons: OverlayButton[] = [];
  private editing = false;

  constructor(
    player: VideoJsPlayer,
    private options: OverlayOptions,
  ) {
    super(player);

    this.renderOverlayButtons(this.options.overlays);
    this.player.on(this.player, 'timeupdate', this.overlayListener);
  }

  private overlayListener = (): void => {
    const time = this.player.currentTime();

    this.overlayButtons.forEach((button) => button.determineVisibility(time));

    if (this.overlayButton) {
      this.overlayButton.determineVisibility(time);
    }
  };

  dispose(): void {
    this.player.off(this.player, 'timeupdate', this.overlayListener);
    super.dispose();
  }

  repositionOverlayMarkers(start: number, end: number): void {
    const { startMarker, endMarker } = this.overlayButton?.editTimeline?.timeline || {};

    const adjustStartTime = (time: number): number => (time < 0 ? 0 : time);

    const adjustEndTime = (time: number): number => {
      const videoDuration = this.player.duration();

      return time <= videoDuration ? time : videoDuration;
    };

    if (startMarker && endMarker) {
      const startTime = NumberUtils.toFixedNum(adjustStartTime(start || 0), 2);
      const endTime = NumberUtils.toFixedNum(adjustEndTime(end || 0), 2);
      const timeFrame: OverlayTimeframe = { start: startTime, end: endTime };

      startMarker.repositionMarker(startTime);
      endMarker.repositionMarker(endTime);

      this.overlayButton.setOverlayVisibilityTimeframe(timeFrame);
      this.overlayButton.editTimeline.timeline.timeFrameUpdatedSource.next(timeFrame);
    }
  }

  setOverlayText(text: string): void {
    const overlayButton = this.overlayButton;

    if (!overlayButton) {
      return;
    }

    overlayButton.setText(text || '...');
    overlayButton.resizeOverlay();
  }

  setOverlayLink(link: string): void {
    const overlayButton = this.overlayButton;

    if (!overlayButton) {
      return;
    }

    overlayButton.setActionLink(link);
  }

  repositionOverlayButtonOnFormChanges(position: OverlayPosition): void {
    const overlay = this.overlayButton;

    if (!overlay) {
      return;
    }

    if (typeof position === 'string') {
      const inlineStylesToRemove = ['top', 'right', 'bottom', 'left', 'transform'];

      OVERLAY_CLASSES.forEach((classToRemove) => {
        removeClass(overlay, classToRemove);
      });

      const overlayElement = overlay.el() as HTMLDivElement;

      inlineStylesToRemove.forEach((styleToRemove) => {
        overlayElement.style[styleToRemove] = null;
      });

      addClass(overlay, 'positioned', position);
    }

    overlay.updatePositionSubject(position);
  }

  toggleEdit(cta: CtaFormModel | null): OverlayButton | null {
    if (cta && !this.editing) {
      this.editing = true;
      this.player.show();
      this.player.pause();
      addClass(this.player, 'no-control-bar', 'no-poster');

      this.overlayButton = this.getEditOverlayButton(cta);
      this.player.addChild(this.overlayButton);
      this.overlayButton.setEditing();

      return this.overlayButton;
    } else if (!cta && this.editing) {
      this.editing = false;
      this.player.currentTime(0);
      removeClass(this.player, 'no-control-bar', 'no-poster');

      if (this.overlayButton) {
        this.overlayButton.setNotEditing();

        if (!this.overlayButton.overlayId) {
          this.overlayButton.hide();
          this.player.removeChild(this.overlayButton);
        }
      }
    }

    return null;
  }

  getEditOverlayButton(overlayDefinition: CtaFormModel | null): OverlayButton {
    const DEFAULT_OVERLAY_DEFINITION: CtaFormModel = {
      ...CTA_FORM_DEFAULT_VALUE,
      buttonText: '...',
    };

    if (!overlayDefinition) {
      return new OverlayButton(this.player, DEFAULT_OVERLAY_DEFINITION, this.options.brandKit);
    }

    const { id } = overlayDefinition;
    const existingOverlayButton = this.overlayButtons.find((button) => button.overlayId === id) || null;

    return existingOverlayButton ?? new OverlayButton(this.player, DEFAULT_OVERLAY_DEFINITION, this.options.brandKit);
  }

  private renderOverlayButtons(overlayDefinitions: OverlayDefinition[] = []): void {
    this.overlayButtons = overlayDefinitions.map(
      (definition) => new OverlayButton(this.player, definition, this.options.brandKit),
    );
    this.overlayButtons.forEach((overlayComponent) => this.player.addChild(overlayComponent));
  }
}

class OverlayTimelineMarker extends ClickableComponent {
  private totalTime: number;
  private curPosition: number;

  mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(
    tap(() => {
      this.removeClass('moving');
    }),
  );

  mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
    distinctUntilChanged((e1, e2) => e1.clientX === e2.clientX),
  );

  mouseDown$ = fromEvent<MouseEvent>(this.el(), 'mousedown').pipe(
    tap((e) => {
      this.addClass('moving');
      e.preventDefault();
      e.stopPropagation();
      const el = this.el() as HTMLButtonElement;
      const parent = el.parentElement;
      const pW = parent.clientWidth;
      this.curPosition = (el.offsetLeft / pW) * 100;
    }),
  );

  mouseDrag$ = this.mouseDown$.pipe(
    switchMap((d) =>
      this.mouseMove$.pipe(
        map((e) => ({ start: d, end: e })),
        startWith({ start: d, end: d }),
        takeUntil(this.mouseUp$),
      ),
    ),
  );

  private dragSub: Subscription;
  private timeSub: Subscription;

  private setTimeSrc = new Subject<number>();
  setTime$ = this.setTimeSrc.asObservable();

  constructor(player: VideoJsPlayer, initialPosition: number) {
    super(player);

    const playerDuration = NumberUtils.toFixedNum(player.duration(), 2);
    this.totalTime = playerDuration;
    const initialLeft = (initialPosition / this.totalTime) * 100;

    const el = this.el() as HTMLDivElement;
    el.style.left = `${initialLeft}%`;

    this.dragSub = this.mouseDrag$.subscribe((e) => {
      const el = this.el() as HTMLDivElement;
      const parent = el.parentElement;
      const oX = e.start.clientX - e.end.clientX;
      const pW = parent.clientWidth;
      const eW = el.clientWidth;
      const percW = oX / pW;
      const maxW = Math.round(100 - (eW / pW) * 100);
      const newW = Math.min(Math.max(Math.round((this.curPosition - percW * 100) * 10) / 10, 0), maxW);
      const newTime = this.totalTime * (newW / 100);

      el.style.left = `${newW}%`;
      this.setTimeSrc.next(NumberUtils.toFixedNum(newTime <= playerDuration ? newTime : playerDuration, 2));
    });

    this.timeSub = this.setTimeSrc.pipe(distinctUntilChanged()).subscribe((time) => {
      this.player().currentTime(time);
    });
  }

  createEl(): Element {
    return videojs.dom.createEl('div', {
      className: 'btn-overlay-timeline-marker',
    });
  }

  repositionMarker(time: number): void {
    const leftOffset = (time / this.totalTime) * 100;
    const el = this.el() as HTMLDivElement;

    el.style.left = `${leftOffset}%`;
  }

  dispose(): void {
    this.dragSub.unsubscribe();
    this.timeSub.unsubscribe();
    this.setTimeSrc.complete();

    super.dispose();
  }
}

class OverlayTimeline extends Component {
  readonly DEFAULT_START_TIME = this.definition.timeFrame.start || 0;
  readonly DEFAULT_END_TIME = this.definition.timeFrame.end || 0;

  startMarker: OverlayTimelineMarker;
  endMarker: OverlayTimelineMarker;

  timeFrameUpdatedSource = new BehaviorSubject<{ start: number; end: number }>({
    start: this.DEFAULT_START_TIME,
    end: this.DEFAULT_END_TIME,
  });
  timeFrameUpdated$ = this.timeFrameUpdatedSource.asObservable();

  constructor(
    player: VideoJsPlayer,
    public definition: OverlayDefinition,
  ) {
    super(player);

    if (this.player().readyState() < 1) {
      player.one('loadedmetadata', () => {
        this.setUp();
      });
    } else {
      this.setUp();
    }
  }

  private setUp(): void {
    this.startMarker = new OverlayTimelineMarker(this.player(), this.DEFAULT_START_TIME);
    this.endMarker = new OverlayTimelineMarker(this.player(), this.DEFAULT_END_TIME);

    this.addChild(this.startMarker);
    this.addChild(this.endMarker);

    const startMarkedChanged$ = this.startMarker.setTime$.pipe(
      startWith(this.definition.timeFrame.start),
      withLatestFrom(this.timeFrameUpdated$),
      map(([start, { end }]) => ({ start, end })),
    );

    const endMarkedChanged$ = this.endMarker.setTime$.pipe(
      startWith(this.definition.timeFrame.end),
      withLatestFrom(this.timeFrameUpdated$),
      map(([end, { start }]) => ({ start, end })),
    );

    merge(startMarkedChanged$, endMarkedChanged$)
      .pipe(debounceTime(0))
      .subscribe((timeFrame) => {
        this.timeFrameUpdatedSource.next(timeFrame);
      });
  }

  createEl(): Element {
    return videojs.dom.createEl('div', {
      className: 'btn-overlay-timeline',
    });
  }

  dispose(): void {
    super.dispose();
  }
}

class OverlayTimelineContainer extends Component {
  timeline: OverlayTimeline;

  constructor(player: VideoJsPlayer, definition: OverlayDefinition) {
    super(player);

    this.timeline = new OverlayTimeline(player, definition);
    this.addChild(this.timeline);
  }

  createEl(): Element {
    return videojs.dom.createEl('div', {
      className: 'btn-overlay-timeline-container',
    });
  }
}

class OverlayButton extends Button {
  editTimeline: OverlayTimelineContainer;
  showing = false;
  editing = false;
  preview = false;
  initialValue: OverlayDefinition | null;

  readonly destroy$ = new Subject();

  private positionUpdatedSource = new Subject<OverlayPosition>();
  positionUpdated$ = this.positionUpdatedSource.asObservable();

  private sizeUpdatedSource = new Subject<OverlaySize>();
  sizeUpdated$ = this.sizeUpdatedSource.asObservable();

  private timeFrameUpdatedSource = new Subject<OverlayTimeframe>();
  timeFrameUpdated$ = this.timeFrameUpdatedSource.asObservable();

  ctaUpdated$ = combineLatest([this.positionUpdated$, this.timeFrameUpdated$]).pipe(
    map(([position, timeFrame]) => ({ timeFrame, position })),
    skip(1),
  );

  mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(
    tap(() => {
      this.removeClass('moving');
      this.extractPosition();
      this.positionUpdatedSource.next(this.curPosition);
    }),
  );

  mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
    distinctUntilChanged((e1, e2) => e1.clientX === e2.clientX && e1.clientY === e2.clientY),
  );

  mouseDown$ = fromEvent<MouseEvent>(this.el(), 'mousedown').pipe(
    filter(() => this.editing && !this.preview),
    tap((e) => {
      this.extractPosition(this.hasClass('positioned'));
      this.removeClass('positioned');
      this.addClass('moving');
      e.preventDefault();
      e.stopPropagation();
    }),
  );

  mouseDrag$ = this.mouseDown$.pipe(
    switchMap((d) =>
      this.mouseMove$.pipe(
        map((e) => ({ start: d, end: e })),
        startWith({ start: d, end: d }),
        takeUntil(this.mouseUp$),
      ),
    ),
  );

  curPosition: OverlayPositionCoordinates;
  curSize?: OverlaySize;
  dragSub: Subscription;

  private overlayPositionInitialized = false;
  private actionLink: string | null = null;
  private _timeframe: OverlayTimeframe = {
    start: 0,
    end: 0,
  };

  private extractPosition(positioned = false): void {
    const el = this.el() as HTMLButtonElement;

    const parent = el.parentElement;
    const pW = parent.clientWidth;
    const pH = parent.clientHeight;

    this.curPosition = adjustPosition({
      x: ((el.offsetLeft - (positioned ? el.clientWidth / 2 : 0)) / pW) * 100,
      y: ((el.offsetTop - (positioned ? el.clientHeight / 2 : 0)) / pH) * 100,
    });
  }

  private readonly playListener = (): void => {
    if (this.editing) {
      this.setPreviewState(true);
    }
  };

  private readonly pauseListener = (): void => {
    if (this.editing && !this.player().seeking()) {
      this.setPreviewState(false);
    }
  };

  get overlayId(): number | null {
    return this.definition.id || null;
  }

  get timeframe(): OverlayTimeframe {
    return this._timeframe;
  }

  get videoDuration(): number {
    return this.player().duration();
  }

  constructor(
    player: videojs.Player,
    public readonly definition: OverlayDefinition,
    public readonly brandKit: CtaBrandKit,
  ) {
    super(player);

    this.initialValue = cloneDeep(this.definition);
    this.curSize = this.definition.size;
    this.addClass('overlay-btn');
    this.setOverlayVisibilityTimeframe(this.definition.timeFrame);
    this.setText(this.definition.buttonText);
    this.setActionLink(this.definition.linkUrl);
    this.applyBrandKit(brandKit);
    this.updateOverlaySize(this.definition.size);
    this.updateOverlayPosition(this.definition.position);
    this.handleVideoPreviewEvents();
    this.hide();
  }

  hide(): void {
    const el = this.el() as HTMLButtonElement;
    el.style.opacity = '0';

    this.showing = false;
  }

  show(): void {
    const el = this.el() as HTMLButtonElement;
    el.style.opacity = '1';

    this.showing = true;
  }

  handleClick(event: videojs.EventTarget.Event): void {
    event.preventDefault();
    event.stopPropagation();

    const prepareUrl = (url: string): string => {
      if (url.match(/^mailto:/i)) {
        return url;
      }

      if (!url.match(/^https?:\/\//i)) {
        return 'https://' + url;
      }

      return url;
    };

    if (!this.editing || this.preview) {
      this.player().pause();
      window.open(prepareUrl(this.actionLink), '_blank', 'noopener');
      this.player().trigger({ type: 'overlayClick', target: this, ctaUrl: this.actionLink });
    }
  }

  setEditing(): void {
    this.editTimeline = new OverlayTimelineContainer(this.player(), this.definition);
    this.player().addChild(this.editTimeline);
    this.player().currentTime(this.definition.timeFrame.start);
    this.editing = true;
    this.addClass('editing');

    const el = this.el() as HTMLButtonElement;
    const parent = el.parentElement;

    this.dragSub = this.mouseDrag$.subscribe((e) => {
      const oX = e.start.clientX - e.end.clientX;
      const oY = e.start.clientY - e.end.clientY;
      const pW = parent.clientWidth;
      const pH = parent.clientHeight;
      const percW = oX / pW;
      const percH = oY / pH;
      const maxW = 100;
      const maxH = 100;
      const newW = Math.min(Math.max(this.curPosition.x - percW * 100, 0), maxW);
      const newH = Math.min(Math.max(this.curPosition.y - percH * 100, 0), maxH);

      this.repositionOverlay(newW, newH);
    });

    this.editTimeline.timeline.timeFrameUpdated$.subscribe((timeFrame) => {
      this.timeFrameUpdatedSource.next(timeFrame);
      this.setOverlayVisibilityTimeframe(timeFrame);
    });

    this.show();
  }

  update(definition: CtaFormModel): void {
    const { buttonText, timeFrame, position, linkUrl, size } = definition;

    this.setText(buttonText);
    this.updateOverlaySize(size);
    this.updateOverlayPosition(position);
    this.setActionLink(linkUrl);
    this.editTimeline.timeline.startMarker.repositionMarker(timeFrame.start);
    this.editTimeline.timeline.endMarker.repositionMarker(timeFrame.start);
  }

  setOverlayVisibilityTimeframe(timeframe: OverlayTimeframe): void {
    this._timeframe = clone(timeframe);
  }

  setNotEditing(): void {
    this.player().removeChild(this.editTimeline);
    this.editTimeline.dispose();
    this.editTimeline = null;
    this.editing = false;
    this.removeClass('editing');
    this.dragSub.unsubscribe();
    this.dragSub = null;

    if (this.initialValue) {
      const { position, buttonText, linkUrl, timeFrame, size } = this.initialValue;

      this.setOverlayVisibilityTimeframe(timeFrame);
      this.setText(buttonText);
      this.setActionLink(linkUrl);
      this.timeFrameUpdatedSource.next(timeFrame);
      this.positionUpdatedSource.next(position);
      this.updateOverlaySize(size);
      this.updateOverlayPosition(position);
    }
  }

  determineVisibility(time: number): void {
    const THRESHOLD_IN_SECONDS = 0.15;

    const { start, end } = this.timeframe;
    const adjustedTime = NumberUtils.toFixedNum(time, 2);

    const shouldShow = adjustedTime >= start && adjustedTime <= end + THRESHOLD_IN_SECONDS;

    if (shouldShow && !this.showing) {
      this.show();
    } else if (!shouldShow && this.showing) {
      this.hide();
    }
  }

  setText(text: string): void {
    this.controlText(text);

    const el = this.el() as HTMLButtonElement;
    el.textContent = text;
  }

  setActionLink(url: string): void {
    this.actionLink = url;
  }

  updateOverlayPosition(position: OverlayPosition): void {
    if (typeof position === 'string') {
      addClass(this, 'positioned', position);
    } else {
      const { x, y } = position;

      this.player().one('ready', () => {
        this.repositionOverlay(x, y);
        this.positionUpdatedSource.next(position);
      });
    }
  }

  updateOverlaySize(size?: OverlaySize): void {
    if (!size) return;
    const { width, height } = size;
    this.player().one('ready', () => {
      this.setOverlayRelativeSize(width, height);
      this.sizeUpdatedSource.next(size);
    });
  }

  updatePositionSubject(position: OverlayPosition): void {
    this.positionUpdatedSource.next(position);
  }

  resizeOverlay() {
    const el = this.el() as HTMLButtonElement;
    el.style.width = 'initial';
    el.style.height = 'initial';

    const parentElement = this.el().parentElement;
    const perW = NumberUtils.toFixedNum((el.offsetWidth / parentElement.clientWidth) * 100, 3) + 1;
    const perH = NumberUtils.toFixedNum((el.offsetHeight / parentElement.clientHeight) * 100, 3) + 1;
    this.setOverlayRelativeSize(perW, perH);
    this.sizeUpdatedSource.next({ width: perW, height: perH });
  }

  dispose(): void {
    this.overlayPositionInitialized = false;
    this.destroy$.next(null);

    if (this.editing && this.dragSub && !this.dragSub.closed) {
      this.dragSub.unsubscribe();
    }

    if (this.editTimeline) {
      this.player().removeChild(this.editTimeline);
      this.editTimeline.dispose();
      this.editTimeline = null;
    }

    this.player().off('play', this.playListener);
    this.player().off('pause', this.pauseListener);
    this.player().off('ended', this.pauseListener);

    super.dispose();
  }

  applyBrandKit(brandKit: CtaBrandKit) {
    const el = this.el() as HTMLButtonElement;
    if (brandKit) {
      const { font, color } = brandKit;
      const { textColor, backgroundColor } = setContrast(color);
      el.style.backgroundColor = backgroundColor;
      el.style.color = textColor;
      el.style.fontFamily = font;
    }
  }

  private handleVideoPreviewEvents(): void {
    this.player().on('play', this.playListener);
    this.player().on('pause', this.pauseListener);
    this.player().on('ended', this.pauseListener);
  }

  private repositionOverlay(x: number, y: number): void {
    const el = this.el() as HTMLButtonElement;
    const p = el.parentElement;

    if (x > 50) {
      const offX = this.curSize?.width ?? NumberUtils.toFixedNum((el.clientWidth / p.clientWidth) * 100, 3);

      el.style.left = 'unset';
      el.style.right = `${Math.max(100 - x - offX, 0)}%`;
    } else {
      el.style.right = 'unset';
      el.style.left = `${x}%`;
    }
    if (y > 50) {
      const offY = this.curSize?.height ?? NumberUtils.toFixedNum((el.clientHeight / p.clientHeight) * 100, 3);

      el.style.top = 'unset';
      el.style.bottom = `${Math.max(100 - y - offY, 0)}%`;
    } else {
      el.style.bottom = 'unset';
      el.style.top = `${y}%`;
    }
  }

  private setOverlayRelativeSize(width: number, height: number): void {
    this.curSize = { width, height };
    const el = this.el() as HTMLButtonElement;
    el.style.width = `${width}%`;
    el.style.height = `${height}%`;
  }

  private setPreviewState(preview: boolean): void {
    this.preview = preview;

    if (preview) {
      this.removeClass('editing');
      this.player().removeClass('no-control-bar');
      this.editTimeline.hide();
    } else {
      this.addClass('editing');
      this.player().addClass('no-control-bar');
      this.editTimeline.show();
    }
  }
}

videojs.registerComponent('OverlayButton', OverlayButton);
videojs.registerPlugin('overlays', OverlayPlugin);

declare module 'video.js' {
  export interface VideoJsPlayer {
    overlays: (overlays?: OverlayOptions) => OverlayPlugin;
  }

  export interface VideoJsPlayerPluginOptions {
    overlays?: OverlayOptions;
  }
}
