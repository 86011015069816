<ng-container *ngIf="!editMode">
  <a
    *ngIf="commentTemplate == 'internal'"
    #commentElement
    class="internal-comment"
    [class.selected]="selected"
    (click)="onClickComment($event, comment)"
  >
    <div class="body">
      <div class="content">
        <div>
          <ngx-avatar
            [value]="comment | commentOwner: 'abbreviate'"
            bgColor="#0066FF"
            fgColor="#FFFFFF"
            size="22"
          ></ngx-avatar>
          <mat-icon *ngIf="comment.annotation" class="annotation-indicator">brush</mat-icon>
        </div>
        <div>
          <span class="owner-name">{{ comment | commentOwner }}</span>
          <p class="comment">{{ comment.comment }}</p>
        </div>
      </div>
      <div class="date-timecode">
        <div>
          <span *ngIf="comment.timecodeMs != null && comment.timecodeMs != undefined" class="timecode">
            Comment at {{ comment.timecodeMs | parseDuration | formatDuration: { forceDisplayHours: true } }}
          </span>
        </div>
        <span class="date" [matTooltip]="comment.createdAt | date: 'dd/MM/yyyy HH:mm'">
          {{ comment.createdAt | relativeDate }}
        </span>
      </div>
    </div>
    <div class="footer">
      <div>
        <ng-container *ngIf="showAccessibility">
          <span class="comment-accessibility">{{ comment.accessibility | commentAccessibility }}</span>
        </ng-container>
      </div>
      <div class="actions">
        <button
          *ngIf="comment.permissions?.update || comment.permissions?.remove"
          [matMenuTriggerFor]="moreActions"
          (click)="stopPropagation($event)"
          class="action-btn more-action-btn"
          mat-icon-button
        >
          <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu class="comment-more-actions-menu" #moreActions="matMenu">
          <button *ngIf="comment.permissions?.update" mat-menu-item (click)="onUpdateComment($event, comment)">
            <span>Edit comment</span>
          </button>
          <button *ngIf="comment.permissions?.remove" mat-menu-item (click)="onDeleteComment($event, comment)">
            <span>Delete comment</span>
          </button>
        </mat-menu>

        <div [matTooltip]="!comment.permissions?.resolve ? 'You don\'t have permission to resolve the comment' : ''">
          <button
            mat-icon-button
            [disabled]="!comment.permissions?.resolve"
            class="action-btn resolve-action-btn"
            [class.resolved]="comment.isResolved"
            (click)="onResolveComment($event, comment)"
          >
            <mat-icon>check</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </a>

  <div *ngIf="commentTemplate == 'widget'" #commentElement class="widget-comment">
    <h4 class="body">
      {{ comment.comment }}
    </h4>
    <div class="details">
      <ngx-avatar
        [value]="comment | commentOwner: 'abbreviate'"
        bgColor="#F9FAFB"
        fgColor="#475467"
        size="30"
      ></ngx-avatar>
      <span class="user-name">{{ comment | commentOwner }}</span>
      <span class="comment-date" [matTooltip]="comment.createdAt | date: 'dd/MM/yyyy HH:mm'">{{
        comment.createdAt | relativeDate
      }}</span>
    </div>
  </div>

  <div *ngIf="commentTemplate == 'video-container'" #commentElement class="video-container-comment">
    <div class="body">
      <ngx-avatar
        [value]="comment | commentOwner: 'abbreviate'"
        bgColor="#F9FAFB"
        fgColor="#475467"
        size="30"
      ></ngx-avatar>
      <div>
        <div class="content">
          <span class="user-name">{{ comment | commentOwner }}</span>
          <span class="comment">{{ comment.comment }}</span>
        </div>
        <div class="footer">
          <span class="comment-date" [matTooltip]="comment.createdAt | date: 'dd/MM/yyyy HH:mm'">
            {{ comment.createdAt | relativeDate }}
          </span>

          <div class="actions">
            <button *ngIf="comment.permissions?.update" type="button" (click)="onUpdateComment($event, comment)">
              Edit
            </button>
            <button *ngIf="comment.permissions?.remove" type="button" (click)="onDeleteComment($event, comment)">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="editMode">
  <form class="comment-form" [formGroup]="updateCommentForm" (ngSubmit)="confirmUpdate()">
    <div class="comment-form-content">
      <mat-form-field class="comment-box" appearance="outline">
        <textarea matInput [rows]="3" formControlName="comment" placeholder="Leave a comment"> </textarea>
        <mat-error *ngIf="updateCommentForm.controls.comment.hasError('maxlength')" class="error-message">
          Comment cannot be more than
          {{ updateCommentForm.controls.comment.getError('maxlength')['requiredLength'] }} characters long
        </mat-error>
      </mat-form-field>
    </div>

    <div class="comment-form-actions">
      <button type="button" mat-stroked-button (click)="closeEditMode()">Cancel</button>
      <button
        type="submit"
        [disabled]="updateCommentForm.invalid || !updateCommentForm.controls.comment.value.length"
        mat-flat-button
        color="primary"
      >
        Update comment
      </button>
    </div>
  </form>
</ng-container>
