import { fromEvent, merge, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import videojs, { VideoJsPlayer } from 'video.js';
const Plugin = videojs.getPlugin('plugin');
const Component = videojs.getComponent('Component');
const Button = videojs.getComponent('Button');

import { SETTINGS_BUTTON_SVG } from './constants';
import { PlayerSettingsOptions } from './player-settings-plugin.interface';
import { PlaybackSpeedMenuItem, PlaybackSpeedSubmenu } from './player-settings-playback-speed';
import { PlaybackQualityMenuItem, QualityPickerSubmenu } from './player-settings-playback-quality';

export class PlayerSettingsPlugin extends Plugin {
  private readonly _player: videojs.Player;
  private readonly _options: PlayerSettingsOptions;

  constructor(player: VideoJsPlayer, options: PlayerSettingsOptions) {
    super(player, options);
    this._player = player;
    this._options = options;

    this.initialise();
  }

  /**
   * Checks if at least one of the settings is available
   */
  isAvailable(): boolean {
    return !!this._options.playbackSpeed || !!this._options.playbackQuality;
  }

  /**
   * Renders settings button if at least one of the settings is available
   */
  initialise(): void {
    if (!this.isAvailable()) {
      return;
    }

    this.player.ready(() => {
      this.addSettingsButtonToControlBar();
    });
  }

  private addSettingsButtonToControlBar(): void {
    const settingsButton = new SettingsButton(this._player, this._options);

    this.player.controlBar.addChild(settingsButton);
  }
}

class SettingsButton extends Button {
  private readonly _options: PlayerSettingsOptions;

  private _overlay: SettingsOverlay | null = null;
  private _subscriptions: Subscription[] = [];

  constructor(player: VideoJsPlayer, options: PlayerSettingsOptions) {
    super(player);

    this._options = options;

    this.initialise();
    this.closeOverlayOnClickOutside();
  }

  createEl(): HTMLButtonElement {
    return super.createEl('button', {
      className: 'vjs-settings-button vjs-menu-button vjs-control vjs-button',
    });
  }

  handleClick(event: videojs.EventTarget.Event): void {
    super.handleClick(event);

    this.toggleOverlay();
  }

  initialise(): void {
    this.contentEl().getElementsByClassName('vjs-icon-placeholder')[0].innerHTML = SETTINGS_BUTTON_SVG;
  }

  dispose(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());

    super.dispose();
  }

  showOverlay(): void {
    this._overlay = new SettingsOverlay(this.player(), this._options);
    this.player().addChild(this._overlay);
  }

  hideOverlay(): void {
    if (this._overlay) {
      this.removeChild(this._overlay);
      this._overlay.dispose();
      this._overlay = null;
    }
  }

  private toggleOverlay(): void {
    if (!this._overlay) {
      this.showOverlay();
    } else {
      this.hideOverlay();
    }
  }

  /**
   * Closes overlay when clicked outside the button or overlay
   * @private
   */
  private closeOverlayOnClickOutside(): void {
    const clickOutside$ = fromEvent(document, 'click').pipe(
      map((event: MouseEvent) => {
        const target = event.target as HTMLElement;

        return !this.el().contains(target);
      }),
    );

    const escClicked$ = fromEvent(document, 'keydown').pipe(map((event: KeyboardEvent) => event.key === 'Escape'));

    const subscription = merge(clickOutside$, escClicked$).subscribe((clickedOutside) => {
      if (clickedOutside) {
        this.hideOverlay();
      }
    });

    this._subscriptions.push(subscription);
  }
}

export class SettingsOverlay extends Component {
  private readonly _options: PlayerSettingsOptions;
  private readonly _player: VideoJsPlayer;

  private _playbackSpeedMenuItem: PlaybackSpeedMenuItem | null = null;
  private _playbackQualityMenuItem: PlaybackQualityMenuItem | null = null;

  constructor(player: VideoJsPlayer, options: PlayerSettingsOptions) {
    super(player);

    this._player = player;
    this._options = options;

    this.setInitialStyles();
    this.renderMenu();
    this.listenClickEvents();
  }

  createEl(): Element {
    return super.createEl('div', {
      className: 'vjs-settings-overlay',
    });
  }

  dispose() {
    this._playbackSpeedMenuItem = null;
    this._playbackQualityMenuItem = null;

    super.dispose();
  }

  private setInitialStyles(): void {
    if (this._options.backgroundColor) {
      this.el().setAttribute('style', `background-color: ${this._options.backgroundColor}`);
    }
  }

  playbackMenuItemClickListener(): void {
    const playbackSpeedSubmenu = new PlaybackSpeedSubmenu(this.player(), {
      playbackSpeed: this._options.playbackSpeed,
      onClose: (playbackSpeed) => {
        this.removeChild(playbackSpeedSubmenu);
        this.renderMenu();

        if (playbackSpeed) {
          this._playbackSpeedMenuItem.setCurrentPlaybackRateLabel(playbackSpeed);
        }
      },
    });

    this.resetMenu();
    this.addChild(playbackSpeedSubmenu);
  }

  private qualityMenuItemClickListener(): void {
    const qualityPickerSubmenu = new QualityPickerSubmenu(this.player(), {
      playbackQuality: this._options.playbackQuality,
      onClose: (quality) => {
        this.removeChild(qualityPickerSubmenu);
        this.renderMenu();

        if (quality) {
          this._playbackQualityMenuItem.setCurrentQualityLabel(quality);
        }
      },
    });

    this.resetMenu();
    this.addChild(qualityPickerSubmenu);
  }

  private resetMenu(): void {
    this.removeChild(this._playbackSpeedMenuItem);
    this.removeChild(this._playbackQualityMenuItem);
  }

  private renderMenu(): void {
    if (this._options.playbackSpeed) {
      this._playbackSpeedMenuItem = new PlaybackSpeedMenuItem(this._player, {
        playbackSpeed: this._options.playbackSpeed,
        onClick: () => this.playbackMenuItemClickListener(),
      });

      this.addChild(this._playbackSpeedMenuItem, null, 0);
    }

    if (this._options.playbackQuality && this._options.playbackQuality.qualityLevels.length) {
      this._playbackQualityMenuItem = new PlaybackQualityMenuItem(this._player, {
        playbackQuality: this._options.playbackQuality,
        onClick: () => this.qualityMenuItemClickListener(),
      });

      this.addChild(this._playbackQualityMenuItem, null, 1);
    }
  }

  private listenClickEvents(): void {
    this.on('click', (e: MouseEvent) => {
      e.stopPropagation();
    });
  }
}

videojs.registerComponent('SettingsButton', SettingsButton);
videojs.registerComponent('SettingsOverlay', SettingsOverlay);

videojs.registerPlugin('playerSettings', PlayerSettingsPlugin);

declare module 'video.js' {
  export interface VideoJsPlayer {
    playerSettings: (options?: PlayerSettingsOptions) => PlayerSettingsPlugin;
    qualityLevel: string;
  }
}
