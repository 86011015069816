import { Observable, concat, debounceTime, map, of } from 'rxjs';

export const fromHtmlElementResizeEvent = (element: HTMLElement): Observable<ResizeObserverEntry[]> => {
  return new Observable((observer) => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      observer.next(entries);
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  });
};

export function fromSizeOfHtmlElement(element: HTMLElement): Observable<{ width: number; height: number }> {
  const initialSize = element.getBoundingClientRect();

  const fromResize$ = fromHtmlElementResizeEvent(element).pipe(
    debounceTime(50),
    map((entries) => {
      const width = entries?.[0]?.borderBoxSize?.[0]?.inlineSize ?? entries?.[0]?.contentRect?.width ?? 0;
      const height = entries?.[0]?.borderBoxSize?.[0]?.blockSize ?? entries?.[0]?.contentRect?.height ?? 0;
      return { width, height };
    }),
  );

  return concat(of({ width: initialSize.width, height: initialSize.height }), fromResize$);
}
