import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Cleanupable } from '@openreel/misc/cleanupable';
import { HostingPlayerVideoEvent } from '@openreel/frontend/common/hosting/hosting-interfaces';

import { AnalyticsService } from './analytics.service';
import { AnalyticsEvent } from './analytics-event';

@Directive({
  standalone: false,
  selector: 'openreel-hosting-player[openreelHostingPlayerEventCapture]',
})
export class HostingPlayerEventCaptureDirective extends Cleanupable implements OnChanges {
  @Input('openreelHostingPlayerEventCapture')
  playerSrcData!: {
    slug: string;
    accountId: string;
    title: string;
    idHash: string;
  };

  constructor(
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.playerSrcData && changes['playerSrcData'].currentValue !== changes['playerSrcData'].previousValue) {
      this.analyticsService.setId(this.playerSrcData.idHash);
      this.analyticsService.setTitle(this.playerSrcData.title);
      this.analyticsService.setAccount(this.playerSrcData.accountId);
      this._logShowVideoEvent();
    }
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (this.document.visibilityState === 'visible') {
      this._logShowVideoEvent();
    }
  }

  @HostListener('videoEvent', ['$event'])
  onVideoEvent(event: HostingPlayerVideoEvent) {
    const { eventName, description } = this._getEventNameAndDescription(event);
    this.analyticsService.logEvent(eventName, description, {
      action: event.action,
      url: event.url,
      position: event.position,
      ...(event.cta_button_url && { cta_button_url: event.cta_button_url }),
    });
  }

  private _logShowVideoEvent() {
    this.analyticsService.logEvent('switch_to_video', 'select video');
  }

  private _getEventNameAndDescription(event: HostingPlayerVideoEvent): AnalyticsEvent {
    switch (event.action) {
      case 'play': {
        return new AnalyticsEvent('video_play', 'Start video');
      }

      case 'pause': {
        return new AnalyticsEvent('video_pause', 'Pausing video');
      }

      case 'seeked': {
        return new AnalyticsEvent('video_seek', 'Seeking in video');
      }

      case 'ended': {
        return new AnalyticsEvent('video_ended', 'Video ended');
      }

      case 'video_completed': {
        return new AnalyticsEvent('video_completed', 'Video play time is at least 90% of the video length');
      }

      case 'cta_button_clicked': {
        return new AnalyticsEvent('cta_button_clicked', 'CTA button clicked');
      }

      case 'toggle_fullscreen': {
        return new AnalyticsEvent('toggle_full_screen', 'Toggle full screen');
      }

      case 'toggle_picture_in_picture': {
        return new AnalyticsEvent('toggle_picture_in_picture', 'Toggle picture in picture mode');
      }

      case 'toggle_captions': {
        return new AnalyticsEvent('toggle_captions', 'Toggle captions');
      }

      case 'toggle_chapters': {
        return new AnalyticsEvent('toggle_chapters', 'Toggle chapters');
      }

      default: {
        return new AnalyticsEvent('unknown', 'Unknown');
      }
    }
  }
}
