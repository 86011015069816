import { Injectable } from '@angular/core';
import {
  OverlayPosition,
  OverlaySize,
} from '@openreel/frontend/common/hosting/video-player/player/overlay-plugin/overlay-position.interface';
import { OverlayTimeframe } from '@openreel/frontend/common/hosting/video-player/player/overlay-plugin/overlay-timeframe.interface';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { CtaFormModel } from '../../hosting-interfaces';
import { OverlayVisibilityOptions } from '../player/overlay-plugin/overlay-visibility-options.interface';

@Injectable()
export class CtaEditorService {
  readonly toggleEditMode$: Observable<CtaFormModel | null>;
  readonly setCtaEditor$: Observable<CtaFormModel>;
  readonly ctaEditorUpdate$: Observable<OverlayVisibilityOptions>;
  readonly ctaTimeFrame$: Observable<OverlayTimeframe>;
  readonly ctaPosition$: Observable<OverlayPosition>;
  readonly ctaSize$: Observable<OverlaySize>;
  readonly duration$: Observable<number>;

  private readonly _toggleEditModeSubject = new BehaviorSubject<CtaFormModel | null>(null);
  private readonly _setCtaEditorSubject = new Subject<CtaFormModel>();
  private readonly _ctaEditorUpdateSubject = new Subject<OverlayVisibilityOptions>();
  private readonly _durationSubject = new ReplaySubject<number>();
  private readonly _timeFrameSubject = new Subject<OverlayTimeframe>();
  private readonly _positionSubject = new Subject<OverlayPosition>();
  private readonly _sizeSubject = new Subject<OverlaySize>();

  constructor() {
    this.toggleEditMode$ = this._toggleEditModeSubject.asObservable();
    this.setCtaEditor$ = this._setCtaEditorSubject.asObservable();
    this.ctaEditorUpdate$ = this._ctaEditorUpdateSubject.asObservable();
    this.duration$ = this._durationSubject.asObservable();
    this.ctaTimeFrame$ = this._timeFrameSubject.asObservable();
    this.ctaPosition$ = this._positionSubject.asObservable();
    this.ctaSize$ = this._sizeSubject.asObservable();
  }

  toggleEditMode(cta: CtaFormModel | null): void {
    this._toggleEditModeSubject.next(cta);
  }

  ctaEditorUpdate(overlayOptions: OverlayVisibilityOptions): void {
    this._ctaEditorUpdateSubject.next(overlayOptions);
  }

  setCtaEditor(value: CtaFormModel): void {
    this._setCtaEditorSubject.next(value);
  }

  setDuration(duration: number): void {
    this._durationSubject.next(duration);
  }

  setPosition(position: OverlayPosition): void {
    this._positionSubject.next(position);
  }

  setRelativeSize(size: OverlaySize): void {
    this._sizeSubject.next(size);
  }

  setTimeFrame(timeFrame: OverlayTimeframe): void {
    this._timeFrameSubject.next(timeFrame);
  }
}
