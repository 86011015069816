/* eslint-disable max-lines-per-function */
import {
  PLATFORM_ID,
  Inject,
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  ViewEncapsulation,
  Optional,
} from '@angular/core';
import { fromHtmlElementResizeEvent } from '@openreel/frontend/common/rxjs-utils';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import videojs from 'video.js';
import { isPlatformBrowser } from '@angular/common';
import './overlay-plugin/overlay-plugin';
import './seekbar-chapters/seekbar-chapters.plugin';
import './seekbar-comments/seekbar-comments.plugin';
import './player-settings-plugin/player-settings.plugin';
import {
  CtaBrandKit,
  HostingPlayerActions,
  HostingPlayerVideoEvent,
  HostingVideoChapter,
  TextButtonCtaDefinition,
  HostingVideoAppearance,
  Comment,
} from '../../hosting-interfaces';
import { NumberUtils } from '../../../number.utils';
import { InternalCommentsManagerService } from '../../../internal-comments/internal-comments-manager.service';
import { addTransparency } from '../../../helpers/color.helper';

interface VideoPlayerData {
  srcUrl: string;
  thumbUrl: string;
  expired: boolean;
  captions?: { readUrl: string; languageDisplay: string; languageCode: string }[];
  cta?: TextButtonCtaDefinition[];
  chapters?: HostingVideoChapter[];
  altText?: string;
  brandKit?: CtaBrandKit;
  appearance?: HostingVideoAppearance;
  qualities?: { label: string; quality: number; srcUrl: string }[];
}

const MOBILE_WIDTH = 400;

@Component({
  selector: 'openreel-hosting-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class HostingPlayerComponent implements AfterViewInit, OnDestroy {
  private _srcData: VideoPlayerData;

  @Input() set srcData(value: VideoPlayerData) {
    this._srcData = value;
    this.changeVideoAppearance(value.appearance);
  }

  get srcData(): VideoPlayerData {
    return this._srcData;
  }

  @Input()
  autoplay = false;

  @Input()
  mute = false;

  @Input()
  border = false;

  @Output() videoEvent = new EventEmitter<HostingPlayerVideoEvent>();

  @ViewChild('videoContainer')
  videoContainer!: ElementRef<HTMLDivElement>;

  @ViewChild('videoEl')
  videoEl!: ElementRef<HTMLVideoElement>;

  @Output() playerReady = new EventEmitter<HostingPlayerActions>();
  @Output() playerDestroyed = new EventEmitter();
  @Output() playerMetadataLoaded = new EventEmitter<void>();

  @Output() clickSeekbarComment = new EventEmitter<Comment>();

  player?: videojs.Player;

  isBrowser: boolean;
  isVideoEndedReported = false;

  readonly duration$: Observable<number>;

  private _durationSubject = new ReplaySubject<number>();

  private _unsubscribers: Array<() => void> = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() private internalCommentsManagerService: InternalCommentsManagerService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.duration$ = this._durationSubject.asObservable();
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.setUpPlayer();
      if (this.srcData.appearance) this.changeVideoAppearance(this.srcData.appearance);
    }
  }

  ngOnDestroy() {
    this._unsubscribers.forEach((unsub) => unsub());
    this.player?.dispose();
    this.playerDestroyed.emit();
  }

  private addWatermark() {
    const videoEl = this.player.el();
    const div = document.createElement('div');
    const img = document.createElement('img');

    div.classList.add('or-watermark-content');
    img.classList.add('or-watermark-content-img');

    img.src = 'assets/common/wordmarks/watermark.png';

    div.appendChild(img);
    videoEl.appendChild(div);
  }

  private setUpPlayer() {
    const videoElem = this.videoEl.nativeElement;
    this.player = videojs(
      videoElem,
      {
        html5: {
          vhs: {
            withCredentials: true,
          },
          mp4: {
            withCredentials: true,
          },
        },
        fluid: true,
        autoplay: this.autoplay,
        muted: this.autoplay ? true : this.mute,
        sources: [{ src: this.srcData.srcUrl }],
        poster: this.srcData.thumbUrl,
        preload: 'auto',
        // textTrackSettings: false as unknown,
        plugins: {
          overlays: { overlays: this.srcData.cta ?? [], brandKit: this.srcData.brandKit },
          seekbarChapters: {
            chapters: this.srcData.chapters || null,
            backgroundColor: this.srcData.appearance ? addTransparency(this.srcData.appearance.primaryColor) : '',
          },
          playerSettings: {
            backgroundColor: this.srcData.appearance ? addTransparency(this.srcData.appearance.primaryColor, 0.8) : '',
            playbackSpeed: this.srcData.appearance.playbackSpeed
              ? {
                  playbackRates: [0.5, 1, 1.5, 2],
                }
              : null,
            playbackQuality: this.srcData.appearance.playbackQuality
              ? {
                  qualityLevels: this.srcData.qualities,
                }
              : null,
          },
        },
      },
      () => {
        this.playerReady.emit({
          play: this.play.bind(this),
          pause: this.pause.bind(this),
          currentTime: this.currentTime.bind(this),
        });

        // add watermark
        if (this.srcData.expired) {
          this.addWatermark();
        }

        this.toggleSmallControlsOnPlayerResize();

        if (this.srcData.appearance.mutedAutoplay) {
          this.player.muted(true);
          this.player.play();
        }
      },
    );

    const emitVideoEvent = (action: string): void => {
      this.videoEvent.emit({ action, url: this.srcData.srcUrl, position: this.getCurrentTime() });
    };

    if (!!this.internalCommentsManagerService) {
      this.player.seekbarComments({
        manager: this.internalCommentsManagerService,
        onClickComment: (comment) => this.clickSeekbarComment.emit(comment),
      });
    }

    this.player.on('play', (evt) => {
      emitVideoEvent('play');
      this.showHideControls('PlayToggle', true);
    });

    this.player.on('pause', (evt) => {
      emitVideoEvent('pause');
    });

    this.player.on('ended', (evt) => {
      emitVideoEvent('ended');
    });

    this.player.on('seeked', (evt) => {
      emitVideoEvent('seeked');
      if (!this.player.hasClass('vjs-has-started')) {
        this.player.addClass('vjs-has-started');
      }
    });

    this.player.on('fullscreenchange', () => {
      if (this.player.isFullscreen()) {
        this.player.removeClass('vjs-small-controls');
      } else {
        if (this.videoContainer.nativeElement.getBoundingClientRect().width < MOBILE_WIDTH) {
          this.player.addClass('vjs-small-controls');
        }
      }
    });

    this.player.on('overlayClick', (evt) => {
      const { ctaUrl = null } = evt;
      const position = this.player.currentTime();

      this.videoEvent.emit({
        action: 'cta_button_clicked',
        url: this.srcData.srcUrl,
        position,
        cta_button_url: ctaUrl,
      });
    });

    this.player.on('timeupdate', (evt) => {
      const position = this.player.currentTime();
      const duration = this.player.duration();
      const played: TimeRanges = this.player.played();
      let durationPlayed = 0;

      for (let i = 0; i < played.length; i++) {
        durationPlayed += played.end(i) - played.start(i);
      }

      // using logic: if user watched >90% of content - means he watched entire video clip
      if (duration > 0 && durationPlayed / duration > 0.9 && !this.isVideoEndedReported) {
        emitVideoEvent('video_completed');
        this.isVideoEndedReported = true;
      }

      if (!!this.internalCommentsManagerService) {
        this.internalCommentsManagerService.setPlayerCurrentTime(position);
      }
    });

    this.player.on('loadedmetadata', () => {
      this.setCustomColor(this.srcData.appearance.primaryColor);
      this.initializeCaptions(this.srcData.captions);
      this.playerMetadataLoaded.emit();
      this._durationSubject.next(NumberUtils.toFixedNum(this.player.duration(), 2));
    });

    this._unsubscribers.push(
      this._addPlayerControlEventListener('PictureInPictureToggle', 'click', () => {
        emitVideoEvent('toggle_picture_in_picture');
      }),
    );

    this._unsubscribers.push(
      this._addPlayerControlEventListener('FullscreenToggle', 'click', () => {
        emitVideoEvent('toggle_fullscreen');
      }),
    );

    this._unsubscribers.push(
      this._addPlayerControlEventListener('ChaptersButton', 'click', () => {
        emitVideoEvent('toggle_chapters');
      }),
    );

    this._unsubscribers.push(
      this._addPlayerControlEventListener('SubsCapsButton', 'click', () => {
        emitVideoEvent('toggle_captions');
      }),
    );
  }

  currentTime(time: number = 0) {
    this.player.currentTime(time);
  }

  getCurrentTime() {
    return this.player.currentTime();
  }

  getDuration() {
    return this.player?.duration();
  }

  private initializeCaptions(captions: VideoPlayerData['captions']): void {
    if (captions?.length) {
      for (let i = 0; i < captions.length; i++) {
        const captionStream = captions[i];

        this.player?.addRemoteTextTrack(
          {
            kind: 'captions',
            label: captionStream.languageDisplay,
            language: captionStream.languageCode,
            id: captionStream.languageCode,
            src: captionStream.readUrl,
          },
          false,
        );
      }
    }
  }

  private async play() {
    if (!this.player) {
      console.warn('Trying to play video before player is initialized');
      return;
    }
    return await this.player.play();
  }

  private pause() {
    if (!this.player) {
      console.warn('Trying to pause video before player is initialized');
      return;
    }
    this.player.pause();
  }

  private changeVideoAppearance(appearance: HostingVideoAppearance): void {
    if (!this.player) return;
    if (appearance.bigPlayButton) {
      this.player.bigPlayButton.show();
    } else {
      this.player.bigPlayButton.hide();
    }
    this.showHideControls('PlayToggle', appearance.smallPlayButton);
    this.showHideControls('VolumePanel', appearance.volumePanel);
    this.showHideControls('ProgressControl', appearance.progressControl);
    this.showHideControls('PictureInPictureToggle', appearance.pictureInPicture);
    if (this.srcData.captions.length > 0) this.showHideControls('SubsCapsButton', appearance.captionsButton);
    if (this.srcData.chapters.length > 0) this.showHideControls('ChaptersButton', appearance.chaptersButton);
    this.showHideControls('FullscreenToggle', appearance.fullscreen);
    this.setCustomColor(appearance.primaryColor);
  }

  private setCustomColor(color: string): void {
    const alphaColor = addTransparency(color);
    this.player.getChild('bigPlayButton').setAttribute('style', `background-color: ${color}`);
    this.player.controlBar.setAttribute('style', `background-color: ${alphaColor}`);
    const menuContents = this.player.contentEl().querySelectorAll('.vjs-menu-content');
    menuContents.forEach((menuContent) => {
      menuContent.setAttribute('style', `background-color: ${alphaColor}`);
    });
    const chaptersTooltips = this.player.contentEl().querySelectorAll('.vjs-seekbar-chapter-tooltip');
    chaptersTooltips.forEach((tooltip) => {
      tooltip.setAttribute('style', `background-color: ${alphaColor}`);
    });
    const seekBar = this.player.controlBar.getChild('progressControl').getChild('seekBar');
    seekBar.addClass('vjs-custom-color');
    seekBar.getChild('playProgressBar').setAttribute('style', `background-color: ${color}; color: ${color}`);
  }

  private showHideControls(controls: string, show: boolean): void {
    const element = this.player.controlBar.getChild(controls);
    if (!element) return;
    if (show) {
      element.show();
      element.setAttribute('style', 'display: flex;');
    } else {
      element.hide();
      element.setAttribute('style', 'display: none;');
    }
  }

  private _addPlayerControlEventListener(
    controlName: string,
    eventName: string,
    listener: (...args: any[]) => void,
  ): () => void {
    const control = this.player.controlBar.getChild(controlName);
    control?.on(eventName, listener);
    return () => control?.off(eventName, listener);
  }

  private toggleSmallControlsOnPlayerResize(): void {
    const resizeSubscription = fromHtmlElementResizeEvent(this.videoContainer.nativeElement)
      .pipe(
        map((entries) => entries[0]?.contentRect?.width),
        filter((width) => !!width),
        distinctUntilChanged(),
        debounceTime(200),
      )
      .subscribe((width) => {
        if (this.player?.isFullscreen()) {
          return;
        }

        if (width < MOBILE_WIDTH) {
          this.player.addClass('vjs-small-controls');
        } else {
          this.player.removeClass('vjs-small-controls');
        }
      });

    this._unsubscribers.push(() => resizeSubscription.unsubscribe());
  }
}
