import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeaderTitleService {
  readonly headerHubTitle$: Observable<string>;
  readonly headerHubSlug$: Observable<string>;
  readonly headerHubIdHash$: Observable<string>;
  readonly headerVideoTitle$: Observable<string>;

  private headerHubTitleSource = new ReplaySubject<string>(1);
  private headerHubSlugSource = new ReplaySubject<string>(1);
  private headerHubIdHashSource = new ReplaySubject<string>(1);
  private headerVideoTitleSource = new ReplaySubject<string>(1);

  constructor() {
    this.headerHubTitle$ = this.headerHubTitleSource.asObservable();
    this.headerHubSlug$ = this.headerHubSlugSource.asObservable();
    this.headerHubIdHash$ = this.headerHubIdHashSource.asObservable();
    this.headerVideoTitle$ = this.headerVideoTitleSource.asObservable();
  }

  setHubSlug(slug: string): void {
    this.headerHubSlugSource.next(slug);
  }

  setHubIdHash(idHash: string): void {
    this.headerHubIdHashSource.next(idHash);
  }

  setHubTitle(title: string): void {
    this.headerHubTitleSource.next(title);
  }

  setVideoHeaderTitle(title: string): void {
    this.headerVideoTitleSource.next(title);
  }
}
