import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { OpenreelValidators } from 'libs/frontend/common/src/validators/validators';

@Component({
  selector: 'openreel-anonymous-user-dialog',
  templateUrl: './anonymous-user-dialog.component.html',
  styleUrls: ['./anonymous-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
})
export class AnonymousUserDialogComponent {
  dialogRef = inject(MatDialogRef);

  userForm = new FormGroup({
    fullname: new FormControl<string>('', [Validators.maxLength(50)]),
    email: new FormControl<string>('', [OpenreelValidators.isEmail(), Validators.maxLength(50)]),
  });

  saveChanges() {
    if (this.userForm.valid) {
      this.dialogRef.close({
        fullname: this._sanitizeString(this.userForm.value.fullname),
        email: this._sanitizeString(this.userForm.value.email),
      });
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  private _sanitizeString(value: string): string | null {
    const v = value.trim();
    return v || null;
  }
}
