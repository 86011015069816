import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PublicHub } from '../interfaces';
import { AnalyticsService } from './analytics.service';

@Directive({
  standalone: false,
  selector: '[openreelHubEventCapture]',
})
export class HubEventCaptureDirective implements OnChanges {
  @Input('openreelHubEventCapture')
  hubData!: PublicHub;

  constructor(
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.hubData && changes['hubData'].currentValue !== changes['hubData'].previousValue) {
      this.analyticsService.setId(this.hubData.idHash);
      this.analyticsService.setHub(this.hubData.idHash);
      this.analyticsService.setTitle(this.hubData.title);
      this.analyticsService.setAccount(this.hubData.accountId);
      this._logShowHubEvent();
    }
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (this.document.visibilityState === 'visible') {
      this._logShowHubEvent();
    }
  }

  private _logShowHubEvent() {
    this.analyticsService.logEvent('show_hub', 'Viewing HUB');
  }
}
