import { SessionType } from './../constants/sessions.constants';
import { AutomationInstanceStatuses } from '@openreel/common/automations';

export enum SearchResultType {
  project = 'project',
  automation = 'automation',
  session = 'session',
  video = 'video',
  hostable = 'library',
  hub = 'hub',
}

interface CommonSearchResultType {
  id: number;
  name: string;
  image?: string;
  date: Date;
  url: SessionUrlType | ProjectUrlType | VideoUrlType | LibraryUrlType | HubUrlType;
  duration?: number;
}

interface CommonUrlType {
  id: number;
}

export interface SessionSearchResult extends CommonSearchResultType {
  type: SearchResultType.session;
  hasAccess: boolean;
  projectId: number;
}

export interface SessionUrlType extends CommonUrlType {
  type: SearchResultType.session;
  sessionType: SessionType;
  code: string;
}

export interface ProjectSearchResult extends CommonSearchResultType {
  type: SearchResultType.project;
}

export interface AutomationSearchResult extends CommonSearchResultType {
  type: SearchResultType.automation;
  icons: string[];
}

export interface ProjectUrlType extends CommonUrlType {
  type: SearchResultType.project | SearchResultType.automation;
}

export interface VideoSearchResult extends CommonSearchResultType {
  type: SearchResultType.video;
}

export interface VideoUrlType extends CommonUrlType {
  type: SearchResultType.video;
}

export interface LibrarySearchResult extends CommonSearchResultType {
  type: SearchResultType.hostable;
}

export interface LibraryUrlType extends CommonUrlType {
  type: SearchResultType.hostable;
}

export interface HubSearchResult extends CommonSearchResultType {
  type: SearchResultType.hub;
}

export interface HubUrlType extends CommonUrlType {
  type: SearchResultType.hub;
}

export type SearchResult =
  | SessionSearchResult
  | ProjectSearchResult
  | AutomationSearchResult
  | VideoSearchResult
  | LibrarySearchResult
  | HubSearchResult;

export interface SearchedKeyword {
  label: string;
  value: string;
  type: 'text' | 'person';
  img?: string;
}
export interface SearchPageVideoResult {
  title: string;
  createdAt: Date;
  duration: number;
  thumbnail: string;
  id: number;
  type: string;
  transcript?: string;
  srcUrl?: string;
  projectId?: number;
}

export interface SearchPageHubResult {
  title: string;
  createdAt: Date;
  id: number;
  type: SearchResultType.hub;
}

export const VideoTypeLabel = {
  pro: 'CAPTURE PRO',
  ugc: 'VIDEO REQUEST',
  self_record: 'SELF RECORD',
  lite: 'CAPTURE LITE',
  library: 'LIBRARY',
};

export enum SearchAiProcessingStatus {
  QueueSent = 'QUEUE_SENT',
  OngoingProcessing = 'ONGOING_PROCESSING',
  Completed = 'COMPLETED',
}

export interface SearchResponseBaseEntity {
  type: 'library' | 'lite' | 'pro' | 'ugc' | 'self_record' | 'hub' | 'project' | 'automation' | 'session';
}

export type SearchableObjects = 'videos' | 'projects' | 'automations' | 'sessions' | 'hubs' | 'videos';

export interface SearchResponse {
  count: number;
  videosCount?: number;
  hubsCount?: number;
  sessionsCount?: number;
  projectsCount?: number;
  automationsCount?: number;
  hubs?: SearchResponseHub[];
  videos?: SearchPageVideoResult[];
  sessions?: SearchResponseSession[];
  projects?: SearchResponseProject[];
  automations?: SearchResponseProject[];
}

export interface SearchResponseHub extends SearchResponseBaseEntity {
  id: number;
  title: string;
  type: 'hub';
  createdAt: Date;
}

export interface SearchResponseVideo extends SearchResponseBaseEntity {
  createdAt: Date;
  duration: number;
  id: number;
  srcUrl?: string;
  thumbnail: string;
  title: string;
  type: 'library';
}

export interface SearchResponseCaptureVideo extends SearchResponseBaseEntity {
  createdAt: Date;
  duration: number;
  id: number;
  projectId: number;
  thumbnail: string;
  title: string;
  transcript: string;
  type: 'lite' | 'pro' | 'ugc' | 'self_record';
}

export interface SearchResponseProject extends SearchResponseBaseEntity {
  id: number;
  title: string;
  type: 'project' | 'automation';
  createdAt: Date;
  thumbnail: string;
  icons?: string[];
  automationInstanceStatus?: AutomationInstanceStatuses;
  url: {
    id: number;
    type: 'project' | 'automation';
  };
}

export interface SearchResponseSession extends SearchResponseBaseEntity {
  id: number;
  title: string;
  type: 'session';
  createdAt: Date;
  hasAccess: boolean;
  projectId: number;
  url: {
    code: string;
    id: number;
    sessionType: 'pro' | 'lite' | 'ugc' | 'self_record';
    type: 'session';
  };
}

export interface SearchResponseVideos {
  captureVideosCount: number;
  libraryVideosCount: number;
  videos: Array<SearchResponseVideo | SearchResponseCaptureVideo>;
}

export interface SearchResponseHubs {
  rows: SearchResponseHub[];
  count: number;
}

export interface SearchResponseProjects {
  rows: SearchResponseProject[];
  count: number;
}

export interface SearchResponseSessions {
  rows: SearchResponseSession[];
  count: number;
}

export interface PeopleInVideoRequestEntity {
  videoId: number;
  videoType: 'hosting' | 'capture';
}

export interface PeopleInVideoResponse {
  facesWithImagesURLs: PeopleInVideoFaceWithImageURL[];
  isAnyProcessOngoing: boolean;
}

export interface PeopleInVideoFaceWithImageURL {
  isLabelled: boolean;
  id: number;
  accountId: number;
  rekognitionUserId: string;
  label: string;
  bucket: string;
  key: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  imageURL: string;
  usersVideosMap: {
    id: number;
    videoId: number;
    videoType: 'hosting' | 'capture';
    rekogCollectionsUsersPkId: number;
    created_at: Date;
    updated_at: Date;
    deletedAt: Date | null;
  }[];
}
