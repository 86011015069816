<div class="error-container" *ngIf="error$ | async as error" [ngSwitch]="error">
  <ng-container *ngSwitchCase="errorTypes.NotFound">
    <div class="error-icon missing"></div>
    <p class="error-header">Video Not Found</p>
    <p class="error-text">We were not able to find the video you are looking for.</p>
    <p class="error-text">Please try again.</p>
  </ng-container>
  <ng-container *ngSwitchCase="errorTypes.Unauthorized">
    <div class="error-icon unauthorized"></div>
    <p class="error-header">Access Denied</p>
    <p class="error-text">You don't have permission to access requested page.</p>
  </ng-container>
  <ng-container *ngSwitchCase="errorTypes.BadRequest">
    <div class="error-icon unauthorized"></div>
    <p class="error-header">Bad Request</p>
    <p class="error-text" *ngIf="message">{{ message }}</p>
    <p class="error-text" *ngIf="!message">You don't have permission to access requested page.</p>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="error-icon missing"></div>
    <p class="error-header">Whoops.. That's embarrassing.</p>
    <p class="error-text">Something went wrong. please try again or contact support for further help.</p>
  </ng-container>
</div>
