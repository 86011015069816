import { Component, Input } from '@angular/core';

@Component({
  selector: 'or-widget-layout',
  templateUrl: './widget-layout.component.html',
  styleUrls: ['./widget-layout.component.scss'],
  standalone: false,
})
export class WidgetLayoutComponent {
  @Input() widgetName: string;
  @Input() isEnableConfigureMode: boolean = false;
}
