import { DOCUMENT, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { Observable, take, switchMap, auditTime } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HeaderTitleService } from '../header/header-title.service';
import { PublicHub } from '../interfaces';
import { HostingListViewService } from './hosting-list-view.service';

@Component({
  standalone: false,
  selector: 'openreel-hosting-public-list',
  templateUrl: './hosting-list.component.html',
  styleUrls: ['./hosting-list.component.scss'],
})
export class HostingListComponent implements AfterViewInit, OnDestroy {
  readonly hubData$: Observable<PublicHub>;

  hostingData$ = this.listViewService.videos$;
  showSpinner$ = this.listViewService.loading$;
  total$ = this.listViewService.total$;
  pageSize$ = this.listViewService.pageSize$;

  localIcons = ['thumbnail-play', 'thumbnail-details', 'hosting-private', 'hosting-public'];

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private viewportScroller: ViewportScroller,
    private listViewService: HostingListViewService,
    private headerTitleService: HeaderTitleService,
    private route: ActivatedRoute,
    private title: Title,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.hubData$ = this.setHubData();

    for (const icon of this.localIcons) {
      const path = `${environment.hostingAppUrl}assets/ui/material-icons/${icon}.svg`;
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.restoreScrollPosition();
    }
  }

  ngOnDestroy(): void {
    this.saveCurrentScrollPosition();
  }

  setTitles(hub: PublicHub): void {
    this.headerTitleService.setHubTitle(hub.title);
    this.headerTitleService.setHubSlug(hub.slug);
    this.headerTitleService.setHubIdHash(hub.idHash);
    this.title.setTitle(hub.title);
  }

  loadNextPage(): void {
    this.listViewService.nextPage();
  }

  /**
   * Update hub data in the store only if hub is changed
   * @private
   */
  private setHubData(): Observable<PublicHub> {
    return this.route.data.pipe(
      map((data) => data.hubData),
      tap((hub: PublicHub) => this.setTitles(hub)),
      switchMap((routeData: PublicHub) => {
        return this.listViewService.hub$.pipe(
          tap((loadedHub) => {
            if (routeData?.slug !== loadedHub?.slug) {
              this.listViewService.setHub(routeData);
            }
          }),
          map(() => routeData),
        );
      }),
    );
  }

  /**
   * Restore scrollbar to the previous position on component initialisation
   * @private
   */
  private restoreScrollPosition(): void {
    this.listViewService.scrollTop$.pipe(auditTime(0), take(1)).subscribe((scrollTop) => {
      this.document.documentElement.scrollTo({
        left: 0,
        top: scrollTop,
        behavior: 'smooth',
      });
    });
  }

  /**
   * Save current scroll position into the store in order to achieve smooth scroll restoration on page revisit
   * @private
   */
  private saveCurrentScrollPosition(): void {
    const [left, top] = this.viewportScroller.getScrollPosition();

    this.listViewService.updateScrollPosition(top);
  }
}
