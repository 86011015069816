import { Observable } from 'rxjs';
import { HostingVideoAppearance } from '@openreel/frontend/common/hosting/hosting-interfaces';

export interface PublicHub {
  slug: string;
  title: string;
  updatedAt: string;
  accountId: string;
  videoCount: number;
  thumbnailSignedUrl: string;
  idHash: string;
}

export interface PublicHostedVideo {
  srcUrl: string;
  thumbnailSignedUrl: string[];
  title: string;
  slug: string;
  idHash: string;
  accountId: string;
  altText: string;
  description: string;
  captions: { readUrl: string; languageCode: string; languageDisplay: string }[];
  duration: number;
  createdAt: Date;
  expired: boolean;
  appearance?: HostingVideoAppearance;
  token?: string;
  enableComments?: boolean;
  hasPassword: boolean;
}

export interface IVideoService {
  getVideoData(idHash: string, slug: string, isEmbed: boolean): Observable<PublicHostedVideo>;
  verifyPassword(idHash: string, password: string): Observable<{ token: string }>;
  getHostname(): string;
}

export interface UserDetails {
  auth_token: string;
  data: {
    loggedin_fullname: string;
    user_id: number;
    account_id: number;
  };
}
