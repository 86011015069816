import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LayoutManagerService {
  private _videoContainerSizeChange = new Subject<void>();
  public readonly videoContainerSizeChange$ = this._videoContainerSizeChange.asObservable();

  public resizeVideoContainer() {
    this._videoContainerSizeChange.next();
  }
}
