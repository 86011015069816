export function filterWhile<T>(
  array: T[],
  filterPredicate: (value: T, index: number, array: T[]) => boolean,
  whilePredicate: (value: T, filtered: T[], index: number, array: T[]) => boolean,
): T[] {
  const result: T[] = [];

  for (let i = 0; i < array.length; i++) {
    if (filterPredicate(array[i], i, array)) {
      result.push(array[i]);
    }

    if (!whilePredicate(array[i], result, i, array)) {
      break;
    }
  }

  return result;
}

// NOTE: Use when array is almost sorted. This sort is very slow in other cases.
export function bubbleSort<T>(
  array: T[],
  compareFn?: (a: T, b: T) => number,
  onSwap?: (index1: number, index2: number) => void,
): T[] {
  let swapped;

  do {
    swapped = false;
    for (let i = 0; i < array.length - 1; i++) {
      if (compareFn ? compareFn(array[i], array[i + 1]) > 0 : array[i] > array[i + 1]) {
        onSwap?.(i, i + 1);

        [array[i], array[i + 1]] = [array[i + 1], array[i]];
        swapped = true;
      }
    }
  } while (swapped);

  return array;
}
